import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Card, Grid, Typography, TextField, Button, Stack, Box, Autocomplete } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmComponent, CustomAlert } from '../utils/Alert';
import Loader from '../components/Loader/Loader';
import { useAddVisitMutation, useGetPackagesQuery, useGetVisitorsQuery } from '../Slices/spaceApi';
import VisitForm from '../components/Visitors/VisitForm';

function BookingEnquiryPage() {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    trigger,
    control,
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const [addVisit, resultStats] = useAddVisitMutation();
  const { data, isLoading } = useGetVisitorsQuery({ spaceId: spaceId || currentSpaceId });

  const [loading, setLoading] = useState(false);

  const [typeAdd, setTypeAdd] = useState('');
  const [messageAdd, setMessageAdd] = useState('');
  const [open, setOpen] = useState(false);

  const [dailog, setDailog] = useState(false);
  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;


  useEffect(() => {
    if (!checkOldUser) {
      setDailog(true);
    } else {
      setDailog(false);
    }
  }, [checkOldUser]);

  const onSubmit = (data) => {
    // eslint-disable-next-line camelcase
    const { expected_visit_datetime, ...otherFields } = data;
    const convertedData = {
      ...otherFields,
      // eslint-disable-next-line camelcase
      expected_visit_datetime: expected_visit_datetime.format('YYYY-MM-DDTHH:mm'),
      visitor: data.visitor.id,
    };

    addVisit({ spaceId: spaceId || currentSpaceId, data: convertedData });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setTypeAdd(true);
      setLoading(false);
      setOpen(true);
      setMessageAdd('Visit created succesfully.');
      navigate('/visitors');
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpen(true);
      setTypeAdd(false);
    }
  }, [resultStats]);

  if (isLoading || pacakgeLoading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>Add Visit | {name || currentName}</title>
      </Helmet>

      <ConfirmComponent openCheck={dailog} />
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Add Visit
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <VisitForm
            register={register}
            data={data}
            control={control}
            errors={errors}
            loading={loading}
            visitorLoading={isLoading}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
          />
        </form>
      </Container>

      <CustomAlert type={typeAdd ? 'success' : 'error'} message={messageAdd} open={open} setOpen={setOpen} />
    </>
  );
}

export default BookingEnquiryPage;
