import { Avatar, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar/Scrollbar';

const ViewEmployee = ({
  openFilter,
  onCloseFilter,
  name,
  email,
  cnicNo,
  phoneNo,
  address,
  description,
  exprienceYears,
  profileImg,
  cnicImage,
}) => (
  <>
    <Drawer
      anchor="right"
      open={openFilter}
      onClose={onCloseFilter}
      PaperProps={{
        sx: { width: { xs: '98%', sm: '375px' }, border: 'none', overflow: 'hidden' },
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          View Employee ({name})
        </Typography>
        <IconButton onClick={onCloseFilter} color='secondary'>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <Divider />
      <Scrollbar>
        <Stack spacing={2} sx={{ p: 1 }}>
          {profileImg && (
            <Avatar
              sx={{
                width: '124px',
                height: '124px',
                mx: 'auto',
              }}
              src={profileImg || '/assets/placeholder.svg'}
              alt="profileImg"
            />
          )}

          {name && (
            <Stack flexDirection={'row'}>
              <Typography fontWeight={600}>Name: </Typography>
              <Typography ml={1}>{name} </Typography>
            </Stack>
          )}

          {email && (
            <Stack flexDirection={'row'}>
              <Typography fontWeight={600}>Email: </Typography>
              <Typography ml={1}>{email} </Typography>
            </Stack>
          )}

          {phoneNo && (
            <Stack flexDirection={'row'}>
              <Typography fontWeight={600}>Phone Number: </Typography>
              <Typography ml={1}>{phoneNo} </Typography>
            </Stack>
          )}

          {address && (
            <Stack flexDirection={'row'}>
              <Typography fontWeight={600}>Address: </Typography>
              <Typography ml={1}>{address} </Typography>
            </Stack>
          )}

          {description && (
            <Stack flexDirection={'row'}>
              <Typography fontWeight={600}>About: </Typography>
              <Typography ml={1}>{description} </Typography>
            </Stack>
          )}

          {exprienceYears && (
            <Stack flexDirection={'row'}>
              <Typography fontWeight={600}>Expirence Years: </Typography>
              <Typography ml={1}>{exprienceYears} </Typography>
            </Stack>
          )}

          {cnicNo && (
            <Stack flexDirection={'row'}>
              <Typography fontWeight={600}>ID Card Number: </Typography>
              <Typography ml={1}>{cnicNo} </Typography>
            </Stack>
          )}

          {cnicImage && (
            <Avatar
              sx={{
                width: '124px',
                height: '124px',
                mx: 'auto',
              }}
              src={cnicImage || '/assets/placeholder.svg'}
              alt="cnicImage"
            />
          )}
        </Stack>
      </Scrollbar>
    </Drawer>
  </>
);

export default ViewEmployee;
