import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText, Stack, Typography } from '@mui/material';
import { useState } from 'react';
//
import { useSelector } from 'react-redux';
import { StyledListItem, StyledNavItem, StyledNavItemIcon } from './styles';
import { useGetPackagesQuery } from '../../Slices/spaceApi';
import { CustomAlert } from '../../utils/Alert';
import Iconify from '../iconify/Iconify';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other} mb={2}>
      <List>
        {data.map((item, idx) => (
          <NavItem key={idx} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

function NavItem({ item }) {
  const { spaceId, currentSpaceId, billingOption, currentBillingOption, userType, currentUserType } = useSelector(
    (item) => item.spaceReducer
  );

  const { data, isLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = data?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  const [open, setOpen] = useState(false);

  const [expanded, setExpanded] = useState(true);

  const location = useLocation();

  const [collapseStates, setCollapseStates] = useState(() =>
    item
      .slice(1)
      .map((item, index) => (item?.child ? location?.pathname.split('/')[1] === item.matcher.toLowerCase() : false))
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpand1Click = (index) => {
    const newCollapseStates = [...collapseStates];
    newCollapseStates[index] = !newCollapseStates[index];
    setCollapseStates(newCollapseStates);
  };

  const totalRemainingHours = data?.assignedPackages
    ?.filter((item) => item.status === 1)
    .reduce((total, item) => {
      const hours = item.remaining_hours.reduce((sum, room) => sum + room.hours, 0);
      return total + hours;
    }, 0);

  const totalRemainingDayPasses = data?.assignedPackages
    .filter((item) => item.purchase && item.status === 1) // Ensure purchase exists and has status 1
    .reduce((total, item) => total + item.purchase.dayPassCount, 0); // Sum up dayPassCount

  return (
    <>
      {Array.isArray(item) && (
        <Box>
          <Typography
            onClick={handleExpandClick}
            component={'p'}
            sx={{
              cursor: 'pointer',
              padding: '16px 0px 8px 0px',
              marginBottom: '4px',
              '&:hover': {
                color: (theme) => theme.palette.text.primary,
              },
            }}
            variant="subtitle2"
          >
            {item[0].toUpperCase()}
          </Typography>

          {item[0].toUpperCase() === 'OVERVIEW' && !isLoading && (
            <Stack flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
              <Box>{+billingOption === 1 || +currentBillingOption === 1 ? 'Hours Left :' : 'Credits Left :'}</Box>
              <Box>{totalRemainingHours?.toFixed(2)}</Box>
            </Stack>
          )}

          {/* {data?.assignedPackages.filter((item) => item.purchase && item.status === 1).length >= 1 && */}
          {item[0].toUpperCase() === 'OVERVIEW' && !isLoading && (
            <Stack flexDirection="row" justifyContent="space-between" sx={{ padding: '0px 0px 16px 0px' }}>
              <Box>Day passes Left : </Box>
              <Box>{totalRemainingDayPasses}</Box>
            </Stack>
          )}

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {item.slice(1).map((itemNext, itemIndex) =>
              itemNext.child ? (
                <Box key={itemIndex}>
                  <StyledListItem
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: (theme) =>
                        location?.pathname.split('/')[1] === itemNext.matcher.toLowerCase()
                          ? theme.palette.action.selected
                          : '',
                      color: (theme) =>
                        location?.pathname.split('/')[1] === itemNext.matcher.toLowerCase()
                          ? theme.palette.action.active
                          : '',
                      fontWeight: location?.pathname.split('/')[1] === itemNext.matcher.toLowerCase() ? '600' : '',
                      '&:hover': {
                        bgcolor: (theme) =>
                          location?.pathname.split('/')[1] === itemNext.matcher.toLowerCase()
                            ? theme.palette.action.activeHover
                            : '',
                      },
                    }}
                    onClick={() => handleExpand1Click(itemIndex)}
                  >
                    <StyledNavItemIcon>{itemNext.icon && itemNext.icon}</StyledNavItemIcon>
                    <ListItemText disableTypography primary={itemNext.title} />

                    {!checkOldUser && itemNext.title === 'Visitor Enquiry' ? (
                      <Iconify icon="solar:lock-outline" sx={{ width: '16px' }} />
                    ) : collapseStates[itemIndex] ? (
                      <Iconify icon="system-uicons:chevron-down" />
                    ) : (
                      <Iconify icon="system-uicons:chevron-up" />
                    )}
                  </StyledListItem>
                  <Collapse in={collapseStates[itemIndex]} timeout="auto" unmountOnExit>
                    {itemNext?.child?.map((item, idx) => (
                      <StyledNavItem
                        key={idx}
                        component={RouterLink}
                        to={item.path}
                        sx={{
                          '&.active': {
                            color: 'text.primary',
                            fontWeight: '600',
                          },
                          padding: '4px 8px 4px 12px;',
                          height: '36px',
                        }}
                      >
                        <Box marginRight={'16px'}>
                          <Typography
                            sx={{
                              width: '4px',
                              height: '4px',
                              borderRadius: '50%',
                              backgroundColor: (theme) => theme.palette.action.active,
                              transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                              transform: 'scale(2)',
                            }}
                          />
                        </Box>

                        <ListItemText disableTypography primary={item.title} />
                        {item.info && item.info}
                      </StyledNavItem>
                    ))}
                  </Collapse>
                </Box>
              ) : (
                <StyledNavItem
                  key={itemIndex}
                  component={RouterLink}
                  to={itemNext.path}
                  sx={{
                    display:
                      ((userType === 'company' || currentUserType === 'company') && itemNext.path === '/employee') ||
                      ((userType !== 'company' || currentUserType !== 'company') && itemNext.path !== '/employee')
                        ? 'flex'
                        : 'none',
                    '&.active': {
                      color: 'action.active',
                      bgcolor: 'action.selected',
                      fontWeight: '600',
                    },
                    '&:hover': {
                      bgcolor: (theme) =>
                        location?.pathname === itemNext.path ? theme.palette.action.activeHover : '',
                    },
                    mb: 0.5,
                  }}
                >
                  <StyledNavItemIcon>{itemNext.icon && itemNext.icon}</StyledNavItemIcon>

                  <ListItemText disableTypography primary={itemNext.title} />
                  {!checkOldUser &&
                    (itemNext.title === 'Community' ||
                      itemNext.title === 'Visitors' ||
                      itemNext.title === 'Community Team' ||
                      itemNext.title === 'Community Search' ||
                      itemNext.title === 'Partners & Offers') && (
                      <Iconify icon="solar:lock-outline" sx={{ width: '16px' }} />
                    )}
                </StyledNavItem>
              )
            )}
          </Collapse>
        </Box>
      )}
    </>
  );
}
