import html2pdf from 'html2pdf.js';
import { formatDateNotTime } from './displayDateTime';

export const downloadPDF = (invoice) => {
  // Generate HTML for the invoice
  const invoiceHTML = `
    <div id="invoice" style="margin:0px 20px 0px 20px;">
    <div>
    <div style="margin-bottom:40px;">
        <div>
          <h1 style="margin:0px">Invoice</h1>
            </div>
        </div>

      <div style="display: flex; justify-content: space-between; align-items:flex-start">
        <div style="display: flex; justify-content: space-between; align-items:flex-start; margin-bottom: 40px">
          <div>
            <h3 style="margin:0px">${invoice?.space?.name || 'N/A'}</h3>
              <p style="margin:0px"> ${invoice?.space?.number || 'N/A'}</p>
              <p style="margin:0px">${invoice?.space?.address || 'N/A'}</p>
              </div>
          </div>

        <div style="min-width:200px;  max-width:200px">
          <p  style="margin:0px">Invoice ID:<b> ${invoice?.id}</b></p>
          <p style="margin:0px">Issued Date: <b>${formatDateNotTime(invoice?.issued_date)} </b></p>
          <p style="margin:0px">Due Date: <b>${formatDateNotTime(invoice?.due_date)} </b></p>
          <p style="margin:0px">Paid: <b> ${invoice?.paid ? 'Yes' : 'No'} </b></p>
        </div>
      </div>
   
      <hr/>


      <div style="display: flex; justify-content: space-between; align-items:flex-start; margin-bottom: 40px">
 
      <div>
          <h3>Invoice To:</h3>
                <h4 style="margin:0px">${invoice?.member?.name || 'N/A'}</h4>
                  <p style="margin:0px"> <b>${invoice?.member?.email || 'N/A'} </b></p>
                  <p style="margin:0px"> <b>${invoice?.member?.phoneNo || 'N/A'} </b></p>    
              <p style="margin:0px"> <b>${invoice?.member?.address || 'N/A'}</b></p>
      </div>


        <div style="min-width:200px;  max-width:200px">
          <h4>Bill To:</h4>
          <p  style="margin:0px">Total Due: <b>${invoice?.total} </b></p>
          <p  style="margin:0px">Bank Name: <b>${invoice?.space?.bankName}</b></p>
          <p style="margin:0px">Branch Name: <b>${invoice?.space?.branchName} </b></p>
          <p style="margin:0px">IBAN: <b>${invoice?.space.iban}</b></p>
          <p style="margin:0px">Swift Code: <b>${invoice?.space.swiftCode} </b></p>
        </div>

      </div>

        <!-- Packages Table -->
        ${
          invoice.assignedPackage?.packages?.length > 0
            ? `
            <div style="margin-bottom: 20px">
              <h3>Packages</h3>
              <table>
              <thead align="center" style="padding: 5px;">
                <tr>
                  <th width="250px" style="padding: 5px;">Name</th>
                  <th width="250px" style="padding: 5px;">Duration</th>
                  <th width="250px" style="padding: 5px;">Rental Fee</th>
                  <th width="250px" style="padding: 5px;">Charges</th>
                  <th width="250px" style="padding: 5px;">Quantity</th>

                </tr>
              </thead>
              <tbody align="center">
                ${invoice.assignedPackage.packages
                  .map(
                    (pkg) => `
                    <tr key=${pkg.id} style="padding: 5px">
                      <td style="padding: 5px;">${pkg.package.name}</td>
                      <td style="padding: 5px;">
                        ${
                          invoice?.assignedPackage?.expiry_duration === 1
                            ? 'Daily'
                            : invoice.assignedPackage?.expiry_duration === 2
                            ? 'Weekly'
                            : 'Monthly'
                        }
                      </td>
                      <td style="padding: 5px;">${pkg.package.rentalFee}</td>
                      <td style="padding: 5px;">${pkg.package.serviceCharges}</td>
                      <td style="padding: 5px;">${pkg.quantity}</td>
                    </tr>
                  `
                  )
                  .join('')}
              </tbody>
            </table>            
            </div>
    <hr/>
            `
            : ''
        }

        <!-- Meeting Packages Table -->
        ${
          invoice.assignedPackage?.meeting_packages?.length > 0
            ? `
            <div style="margin-bottom: 20px">
              <h3>Meeting Packages</h3>
              <table >
                <thead style="padding: 5px;">
                  <tr align="center">
                    <th width="250px" style="padding: 5px;"> Name</th>
                    <th width="250px" style="padding: 5px;">Duration</th>
                  <th width="250px" style="padding: 5px;">Rental Fee</th>
                  <th width="250px" style="padding: 5px;"> Charges</th>
                  <th width="250px" style="padding: 5px;"> Quantity</th>

                  </tr>
                </thead>
                <tbody  align="center">
                  ${invoice.assignedPackage.meeting_packages
                    .map(
                      (meetingPkg) => `
                      <tr key=${meetingPkg.id}>
                        <td style="padding: 5px;">${meetingPkg.package.name}</td>
                        <td style="padding: 5px;">${
                          invoice?.assignedPackage?.expiry_duration === 1
                            ? 'Daily'
                            : invoice.assignedPackage?.expiry_duration === 2
                            ? 'Weekly'
                            : 'Monthly'
                        }</td>

                      <td style="padding: 5px;">${meetingPkg.package.rentalFee}</td>
                      <td style="padding: 5px;">${meetingPkg.package.serviceCharges}</td>
                      <td style="padding: 5px;">${meetingPkg.quantity}</td>
                      </tr>
                    `
                    )
                    .join('')}
                </tbody>
              </table>
            </div>
            <hr/>
          `
            : ''
        }
    </div>

    <div style="display:flex; justify-content:flex-end; flex-direction:column; width:100% ;margin-top:40px; align-items: flex-end">
        <div style="min-width:200px; max-width:200px">
        <p style="margin:0px;">Sub Total:  <b>${invoice?.subtotal} </b></p>
          <p style="margin:0px;">${
            invoice?.setOveralTax
              ? `Overal Tax:  <b>${invoice?.serviceCharges} % </b>`
              : `Service Charges: <b>${invoice?.serviceCharges} % </b>`
          } </p>
          <p style="margin:0px;">
          ${invoice?.setOveralTax ? `` : `Rental Fee: <b>${invoice?.rentalFee}</b>`}
          </p>
          <p style="margin:0px;">Discount: <b>${invoice?.discount_percentage}% </b></p>
        <p style="margin:0px;">Adjustment: <b>${invoice?.adjustment} </b></p>
 <hr/>
          <p style="margin:0px;">Total: <b> ${invoice?.total} </b></p>
        </div>
      </div>
    </div>
  `;

  const pdfOptions = {
    filename: `INV—${formatDateNotTime(invoice.due_date)}.pdf`, // Specify the desired file name
    margin: 20,
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };

  html2pdf().from(invoiceHTML).set(pdfOptions).save();
  // html2pdf().from(invoiceHTML).save();
};
