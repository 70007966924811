import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  TextField,
  Card,
} from '@mui/material';
// components
import { useCallback, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import { timestampToDateTime } from '../../utils/timeStamptoDateTime';
import { calculateDuration } from '../../utils/getDuration';
import { CustomAlert } from '../../utils/Alert';
import { useUpdatePostMutation, useUpdateVisitMutation, useUpdateVisitorMutation } from '../../Slices/spaceApi';
import { maxFileSize } from '../../utils/maxFileSize';

export default function ShopFilterSidebar({ openFilter, onCloseFilter, image, content, id, title, link }) {

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');
  const [hovered, setHovered] = useState(false);
  const [typeImage, setTypeImage] = useState('');
  const [openImage, setOpenImage] = useState(false);

  const [updatePost, resultStats] = useUpdatePostMutation();
  const [selectedImage, setSelectedImage] = useState(null);

  const [postData, setPostData] = useState({
    image,
    content,
    title: title || '',
    link: link || '',
  });

  useEffect(() => {
    setPostData({
      image,
      content,
      title: title || '',
      link: link || '',
    });

    setSelectedImage(null);
  }, [id]);

  useEffect(() => {
    if (selectedImage) {
      setLoading(true);
      if (
        selectedImage.type &&
        (selectedImage.type.includes('image/jpeg') ||
          selectedImage.type.includes('image/jpg') ||
          selectedImage.type.includes('image/png'))
      ) {
        const fd = new FormData();
        fd.append('image', selectedImage); // Append the selected file to the FormData
        axios
          .post('/uploadImage/', fd)
          .then((resp) => {
            setLoading(false);
            setOpenImage(true);
            setTypeImage(true);
            setPostData({ ...postData, image: resp.data?.[0] });
          })
          .catch((err) => {
            setLoading(false);
            setOpenImage(false);
            setTypeImage(false);
          });
      } else {
        setSelectedImage('');
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      }
    }
  }, [selectedImage]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        // File size exceeds the limit, you can display an error message here
      } else {
        setSelectedImage(selectedFile);
      }
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const save = () => {
    if (!selectedImage) {
      updatePost({
        spaceId: spaceId || currentSpaceId,
        postId: id,
        data: { content: postData?.content, title: postData?.title, link: postData?.link },
      });
    } else {
      updatePost({ spaceId: spaceId || currentSpaceId, postId: id, data: postData });
    }
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setMessageAdd('Post updated successfully.');
      setType(true);
      setLoading(false);
      setOpen(true);
      onCloseFilter();
      setSelectedImage(null);
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  const openInput = () => {
    document.getElementById('ali').click(); // Trigger the file input when the image container is clicked
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '420px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Update Post
          </Typography>
          <IconButton onClick={onCloseFilter} color="secondary">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Card sx={{ padding: 3 }}>
            <Box mb={3} sx={{ textAlign: 'center' }}>
              <Box
                sx={{
                  padding: '8px',
                  border: '1px dashed rgba(145, 158, 171, 0.2)',
                  borderRadius: '50%',
                  overflow: 'hidden',
                  width: '144px',
                  height: '144px',
                  cursor: 'pointer',
                  marginX: 'auto',
                }}
              >
                <Box
                  sx={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={openInput}
                >
                  <img
                    src={
                      selectedImage instanceof File
                        ? URL.createObjectURL(selectedImage)
                        : postData?.image || '/assets/placeholder.svg'
                    }
                    alt="Uploaded Avatar"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  {(hovered || (selectedImage instanceof File ? false : !postData?.image)) && (
                    <>
                      <Box
                        style={{
                          top: '0',
                          position: 'absolute',
                          left: '0',
                          backgroundColor: 'rgb(0 0 0 / 50%)',
                          color: 'white',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <Box
                          component={'span'}
                          sx={{
                            top: '50%',
                            position: 'absolute',
                            left: '50%',
                            transform: 'translate(-50% , -50%)',
                          }}
                        >
                          <Iconify icon="bxs:camera-plus" />
                          <Typography component={'span'} sx={{ textWrap: 'nowrap' }} variant="caption">
                            Update photo
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <Box mt={0.5}>
                <input
                  type="file"
                  accept=".jpeg, .jpg, .png"
                  id="ali"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Box>
              <Typography variant="caption" mt={3} sx={{ color: (theme) => theme.palette.text.disabled }}>
                Allowed *.jpeg, *.jpg, *.png
                <br /> max size of 3.1 MB
              </Typography>
            </Box>

            <TextField
              value={postData?.title}
              onChange={(e) => setPostData({ ...postData, title: e.target.value })}
              variant="outlined"
              placeholder="Title"
              fullWidth
              sx={{ mb: 2 }}
            />

            <TextField
              value={postData?.link}
              onChange={(e) => setPostData({ ...postData, link: e.target.value })}
              variant="outlined"
              placeholder="Link"
              fullWidth
              sx={{ mb: 2 }}
            />

            <TextField
              value={postData?.content}
              onChange={(e) => setPostData({ ...postData, content: e.target.value })}
              variant="outlined"
              placeholder="Share what are you thinking here..."
              fullWidth
              multiline
              rows={5}
            />
          </Card>
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown, p: 2 }}>
          <Box>
            <LoadingButton
              loading={loading}
              disabled={!postData?.content && !postData?.link && !postData?.title}
              variant="contained"
              sx={{ float: 'right' }}
              onClick={save}
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                Update Post
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>

      {
        <CustomAlert
          type={typeImage ? 'success' : 'error'}
          message={typeImage ? 'Image uploaded sucessfully.' : 'Failed to upload an image.'}
          open={openImage}
          setOpen={setOpenImage}
        />
      }

      {<CustomAlert type={type ? 'success' : 'error'} message={messageAdd} open={open} setOpen={setOpen} />}
    </>
  );
}
