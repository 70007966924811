// Import react-hook-form
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import Iconify from '../iconify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function VisitorSummary({
  handleClose,
  open,
  onClick,
  loading,
  registerVisitor,
  errorsVisitor,
  setValue,
  watch,
  trigger,
}) {
  useEffect(() => {
    setValue('visitors', [{ name: '' }]);
  }, []);

  const handleAddVisitor = () => {
    setValue('visitors', [...watch('visitors'), { name: '' }]);
  };

  const handleRemoveVisitor = (idx) => {
    const updatedVisitors = watch('visitors').filter((_, index) => index !== idx);
    setValue('visitors', updatedVisitors);
  };


  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: {
              sm: '370px', // Set minimum width for sm and larger screens
              xs: '90%', // Set minimum width for xs screens
            },
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Include Visitors
        </DialogTitle>
        <IconButton
          color="secondary"
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Iconify icon="eva:close-fill" />
        </IconButton>

        <DialogContent dividers>
          <Stack spacing={2}>
            {watch('visitors')?.map((visitor, idx) => (
              <Stack key={idx} justifyContent={'space-between'} flexDirection={'row'} alignItems={'flex-start'}>
                <TextField
                  id={`name_${idx}`}
                  label={`Visitor Name ${idx + 1}`}
                  size="small"
                  value={visitor.name}
                  {...registerVisitor(`visitors.${idx}.name`, {
                    required: `Visitor name ${idx + 1} is required`, // Custom error message
                  })}
                  error={!!errorsVisitor?.visitors?.[idx]?.name}
                  helperText={errorsVisitor?.visitors?.[idx]?.name?.message || ''}
                  variant="outlined"
                  fullWidth
                  onBlur={() => trigger(`visitors.${idx}.name`)}
                  onChange={(e) => {
                    const updatedVisitors = [...watch('visitors')];
                    updatedVisitors[idx].name = e.target.value;
                    setValue('visitors', updatedVisitors);
                  }}
                />

                {idx !== 0 ? (
                  <IconButton sx={{ ml: 1 }} color="secondary" size="small" onClick={() => handleRemoveVisitor(idx)}>
                    <Iconify icon="eva:close-fill" />
                  </IconButton>
                ) : null}

                {idx === watch('visitors').length - 1 ? (
                  <IconButton sx={{ ml: 1 }} color="secondary" size="small" onClick={handleAddVisitor}>
                    <Iconify icon="material-symbols:add" />
                  </IconButton>
                ) : null}
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={onClick} variant="contained" fullWidth>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
              Include Visitors
            </Stack>
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
