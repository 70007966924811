/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Container,
  Select,
  MenuItem,
  Chip,
  Popover,
  Button,
  useMediaQuery,
  Link,
  Stack,
  IconButton,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import { formatDateNotTime } from '../utils/displayDateTime';
import Loader from '../components/Loader/Loader';
import { useGetPackagesQuery } from '../Slices/spaceApi';

const PackagesPage = () => {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);

  const navigate = useNavigate();

  const { data, isLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const [statusFilter, setStatusFilter] = useState('');

  const filteredRows = data?.assignedPackages.filter(
    (row) =>
      statusFilter === '' ||
      (statusFilter === 'active' ? row.status === 1 : statusFilter === 'pending' ? row.status === 2 : row.status === 3)
  );

  const purchases = filteredRows?.map((row) => row?.purchase)?.filter((purchase) => purchase !== null);


  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Purchases | {name || currentName} </title>
      </Helmet>

      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5 }}>
          <Typography variant="h4">Purchases</Typography>
        </Box>

        <TableContainer component={Paper}>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Typography mr={1}>Filters</Typography>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} displayEmpty>
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="active">Paid</MenuItem>
              <MenuItem value="pending">Unpaid</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
            </Select>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>Items</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchases?.length > 0 ? (
                purchases?.map((row) => {
                  const totalQuantity = row.items.reduce((acc, item) => acc + item.quantity, 0);

                  return (
                    <TableRow key={row?.id} hover>
                      <TableCell>#{row?.id}</TableCell>
                      <TableCell>
                        <Stack>
                          <Box>{formatDateNotTime(row.date)}</Box>
                          <Typography variant="subtitle2">{dayjs(row.date).format('hh:mm A')}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{totalQuantity}</TableCell>
                      <TableCell>
                        <IconButton color="secondary">
                          <Iconify
                            icon={'solar:eye-bold'}
                            onClick={() =>
                              navigate(
                                `/purchase/${
                                  filteredRows.find((filteredRow) => filteredRow.purchase?.id === row.id)?.id
                                }`
                              )
                            }
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No Purchase Available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default PackagesPage;
