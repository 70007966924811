import {
  Box,
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Modal,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  Button,
  Paper,
  TextField,
  Grid,
  Chip,
  Link,
} from '@mui/material';
// components
import { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from 'axios';
import {
  BOOKINGCONFIGURATION,
  CREDITPRICEINCLUSIVETAX,
  CURRENCY,
  OPENDAYS,
  OPENDAYSTIME,
  SPACEBILLINGOPTIONS,
  SPACECREDS,
  SPACEID,
  SPACELOGO,
  SPACENAME,
  SPACEUSERID,
  STRIPE,
  TAXRATEOBJ,
  THEMECOLOR,
  USERTYPE,
} from '../../Slices/spaceSlice';
import SetCookie from '../../utils/setCookie';
import {
  useCreatePublicHotDeskBookingMutation,
  useCreatePublicMeetingRoomBookingMutation,
  useSendOtpMutation,
} from '../../Slices/constantApi';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import { useCreateBookingMutation, useHotDesksBookingMutation, useUpdateBookingMutation } from '../../Slices/spaceApi';
import { CustomAlert } from '../../utils/Alert';
import EnquiryForm from './EnquiryForm';
import { removeFieldsWithId } from '../../utils/removeIdFromFieldNames';
import BookingSummary from './BookingSummary';
import { getNextOpenDate } from '../../utils/nextOpenDay';
import VisitorSummary from './IncludeVisitorSummary';

export default function ShopFilterSidebar({
  openFilter,
  onCloseFilter,
  room,
  id,
  type: bookingType,
  weekdays,
  locationId,
  publicRoom,
  meetingRoomCurrency,
  hotDeskDate,
  inclusiveTax,
  weekdaysTime,
  globalTaxRateObj: globalTaxRateObjPublic,
  currentGlobalTaxRateObj: currentGlobalTaxRateObjPublic,
  publicBillingOption,
  publicCreditPriceInclusiveTax,
  stripe,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const Dispatch = useDispatch();

  const {
    control: controlVisitor,
    handleSubmit: handleVisitor,
    formState: { errors: errorsVisitor },
    register: registerVisitor,
    trigger: triggerVisitor,
    watch: watchVisitor,
    setValue: setValueVisitor,
  } = useForm();

  const {
    currentUserId,
    userId,
    spaceId,
    currentSpaceId,
    openDays,
    currentOpenDays,
    openDaysTime,
    currentOpenDaysTime,
    globalTaxRateObj,
    currentGlobalTaxRateObj,
  } = useSelector((item) => item.spaceReducer);

  const navigate = useNavigate();

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState('');

  const [bookingId, setBookingId] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const [incudeVisitors, resultStats4] = useUpdateBookingMutation();

  const [createBooking, resultStats] = useCreateBookingMutation();

  const [createPublicMeetingRoomBooking, resultStats2] = useCreatePublicMeetingRoomBookingMutation();

  const [createHotdesksBooking, resultStats1] = useHotDesksBookingMutation();
  const [createPublicHotDeskBookingMutation, resultStats3] = useCreatePublicHotDeskBookingMutation();

  const [sendOtp, resultStats5] = useSendOtpMutation();

  const [openVisitorModal, setOpenVisitorModal] = useState(false);

  const [otp, setOTP] = useState('');
  const [verified, setVerified] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const inputRefs = useRef([]);

  const [resendCountdown, setResendCountdown] = useState(0);

  const handleInputChange = (index, value) => {
    const newOTP = otp.substr(0, index) + value + otp.substr(index + 1);
    setOTP(newOTP);

    // Move focus to the next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleCloseVisitor = () => {
    setOpenVisitorModal(false);
  };

  const onSubmitVisitor = (data) => {
    incudeVisitors({ data: { visitor: data?.visitors }, spaceId: spaceId || currentSpaceId, bookingId });
  };

  const onSubmit = async (data, resend, verify) => {
    if (!openModal) {
      setOpenModal(true);
      return;
    }

    const updatedData = removeFieldsWithId(data, id);

    const dayOfWeek = dayjs(updatedData?.startDate.format('YYYY-MM-DD')).day();

    // Adjust to make Monday as 1 and Sunday as 7
    const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

    // Assuming startTimeForDay is in 'HH:mm' format (e.g., '08:30')
    const startTimeForDay = publicRoom
      ? weekdaysTime?.find((item) => item.day === adjustedDayOfWeek)?.start_time
      : openDaysTime.length > 0
      ? openDaysTime?.find((item) => item.day === adjustedDayOfWeek)?.start_time
      : JSON.parse(currentOpenDaysTime)?.find((item) => item.day === adjustedDayOfWeek)?.start_time;

    // Parse the timestamp using dayjs
    const startTimeAsDate = dayjs.unix(startTimeForDay);

    // Get the formatted time (HH:mm)
    const formattedTime = startTimeAsDate.format('HH:mm');

    // Assuming targetDate is defined elsewhere in your code
    const targetDate = updatedData?.startDate.format('YYYY-MM-DD');

    // Combine targetDate and startTimeForDay
    const combinedDateTime = dayjs(`${targetDate} ${formattedTime}`);

    // Get the new timestamp in seconds
    const newTimestamp = combinedDateTime.unix();

    if (publicRoom && bookingType === 1) {
      if (!dialogOpen) {
        setDialogOpen(true);

        sendOtp({
          spaceId: locationId,
          data: {
            name: updatedData?.name,
            email: updatedData?.email,
            phone_number: updatedData?.phone_number,
          },
        });
      } else if (resend) {
        setResendCountdown(59);

        sendOtp({
          spaceId: locationId,
          data: {
            name: updatedData?.name,
            email: updatedData?.email,
            phone_number: updatedData?.phone_number,
          },
        });
      } else if (verify) {
        try {
          const response = await axios.post(`/space/${locationId}/profile/verifyOtp/`, {
            otp,
            email: updatedData?.email,
          });

          SetCookie('token', response?.data?.['login-token'].token);
          SetCookie('userId', response?.data?.userId);
          SetCookie('spaceId', response?.data?.Spaces?.[0]);
          SetCookie('creds-member', response?.data?.creds);
          SetCookie('billing_option', response?.data?.billing_option);
          SetCookie('currency', response?.data?.currency);
          SetCookie('openDays', JSON.stringify(response?.data?.openDays));
          SetCookie('spaceLogo', response?.data?.imageUrls);
          SetCookie('spaceName', response?.data?.name);
          SetCookie('booking_configuration', response?.data?.booking_configuration);
          SetCookie('openDaysTime', JSON.stringify(response?.data?.openDaysTime));
          SetCookie('userType', response?.data?.userType);


          SetCookie('stripe', response?.data?.stripe);
          Dispatch(STRIPE(response?.data?.stripe));

          SetCookie(
            'taxRateObj',
            JSON.stringify(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : JSON.stringify({
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  })
            )
          );

          SetCookie('creditPriceInclusiveTax', response?.data.creditPriceInclusiveTax || true);

          const brandColors = response?.data?.brandColors;

          SetCookie('themeColor', JSON.stringify(brandColors));

          Dispatch(THEMECOLOR(brandColors));

          Dispatch(CURRENCY(response?.data?.currency));
          Dispatch(SPACECREDS(response?.data?.creds));
          Dispatch(SPACEUSERID(response?.data?.userId));
          Dispatch(SPACEID(response?.data?.Spaces?.[0]));
          Dispatch(SPACEBILLINGOPTIONS(response?.data?.billing_option));
          Dispatch(OPENDAYS(response?.data?.openDays));
          Dispatch(SPACELOGO(response?.data?.imageUrls));
          Dispatch(SPACENAME(response?.data?.name));
          Dispatch(OPENDAYSTIME(response?.data?.openDaysTime));
          Dispatch(USERTYPE(response?.data?.userType));
          Dispatch(CREDITPRICEINCLUSIVETAX(response?.data.creditPriceInclusiveTax || true));
          Dispatch(BOOKINGCONFIGURATION(response?.data?.booking_configuration));

          Dispatch(
            TAXRATEOBJ(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : {
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  }
            )
          );

          setVerified(true);
          setOtpError(false);
          createPublicMeetingRoomBooking({
            spaceId: locationId,
            data: {
              startTime: updatedData?.startTimestamp,
              endTime: updatedData?.endTimestamp,
              capacity: room?.capacity,
              meetingRoom: room?.id,
              ...(updatedData?.discountCode && { discountCode: updatedData?.discountCode }),
              publicUser: {
                name: updatedData?.name,
                email: updatedData?.email,
                phone_number: updatedData?.phone_number,
              },
            },
          });
        } catch (error) {
          setOTP('');
          setVerified(false);
          setOtpError(true);
        }
      }
    } else if (publicRoom && bookingType === 2) {
      createPublicHotDeskBookingMutation({
        spaceId: locationId,
        data: {
          startTime: newTimestamp,
          endTime: dayjs(updatedData?.startDate.format('YYYY-MM-DD')).endOf('day')?.unix(),
          capacity: room?.capacity,
          publicUser: {
            name: updatedData?.name,
            email: updatedData?.email,
            phone_number: updatedData?.phone_number,
          },
        },
      });
    } else if (bookingType === 2) {
      createHotdesksBooking({
        spaceId: spaceId || currentSpaceId,
        data: {
          member: +userId || +currentUserId,
          startTime: newTimestamp,
          endTime: dayjs(updatedData?.startDate.format('YYYY-MM-DD')).endOf('day')?.unix(),
          capacity: room?.capacity,
          type: bookingType,
        },
      });
    } else {
      createBooking({
        spaceId: spaceId || currentSpaceId,
        data: {
          member: +userId || +currentUserId,
          startTime: updatedData?.startTimestamp,
          endTime: updatedData?.endTimestamp,
          capacity: room?.capacity,
          meetingRoom: room?.id,
          ...(updatedData?.discountCode && { discountCode: updatedData?.discountCode }),
        },
      });
    }
  };

  useEffect(() => {
    if (resultStats2?.isLoading) {
      setLoader(true);
    } else if (resultStats2?.isSuccess) {
      setLoader(false);

      // setDialogOpen(true);
    } else if (resultStats2?.isError) {
      const errorMessage = Object.values(resultStats2?.error?.data).find((error) => error.length > 0);
      setMessage(errorMessage);
      setLoader(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats2]);

  // Countdown timer for resend functionality
  useEffect(() => {
    let intervalId;
    if (resendCountdown > 0) {
      intervalId = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendCountdown]);

  useEffect(() => {
    if (resultStats3?.isLoading) {
      setLoader(true);
    } else if (resultStats3?.isSuccess) {
      setLoader(false);

      const dayOfWeek = dayjs(watch(`startDate_${id}`).format('YYYY-MM-DD')).day();

      const adjustedDayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;

      const startTimeForDay = publicRoom
        ? weekdaysTime?.find((item) => item.day === adjustedDayOfWeek)?.start_time
        : openDaysTime.length > 0
        ? openDaysTime?.find((item) => item.day === adjustedDayOfWeek)?.start_time
        : JSON.parse(currentOpenDaysTime)?.find((item) => item.day === adjustedDayOfWeek)?.start_time;

      const startTimeAsDate = dayjs.unix(startTimeForDay);

      const formattedTime = startTimeAsDate.format('HH:mm');

      const targetDate = watch(`startDate_${id}`).format('YYYY-MM-DD');

      const combinedDateTime = dayjs(`${targetDate} ${formattedTime}`);

      const newUrl = `/booked?created_at=${dayjs(combinedDateTime).unix()}`;

      navigate(newUrl);
    } else if (resultStats3?.isError) {
      const errorMessage = Object.values(resultStats3?.error?.data).find((error) => error.length > 0);
      setMessage(errorMessage);
      setLoader(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats3]);

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoader(true);
    } else if (resultStats?.isSuccess) {
      setType(true);
      setOpenModal(false);
      setLoader(false);
      setOpen(true);
      setMessage('Booked meeting room successfully.');
      setBookingId(resultStats?.data?.id);
      setOpenVisitorModal(true);
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessage(errorMessage);
      setLoader(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isLoading) {
      setLoader(true);
    } else if (resultStats1?.isSuccess) {
      setType(true);
      setOpenModal(false);
      setLoader(false);
      setOpen(true);
      setMessage('Booked hotdesk successfully.');

      navigate('/bookings?hotdesk=true');
    } else if (resultStats1?.isError) {
      const errorMessage = Object?.values(resultStats1?.error?.data).find((error) => error?.length > 0);
      setMessage(errorMessage);
      setLoader(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats1]);

  useEffect(() => {
    if (resultStats4?.isLoading) {
      setLoader(true);
    } else if (resultStats4?.isSuccess) {
      setType(true);
      setOpenVisitorModal(false);
      setLoader(false);
      setOpen(true);
      // setMessage('Visitors included successfully.');
      navigate('/bookings');
    } else if (resultStats4?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessage(errorMessage);
      setLoader(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats4]);

  useEffect(() => {
    const backendData = {
      [`startDate_${id}`]: getNextOpenDate(publicRoom ? weekdays : openDays, publicRoom ? weekdays : currentOpenDays),
    };

    Object.keys(backendData).forEach((key) => {
      setValue(key, backendData[key]);
    });
  }, [id]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const addSpace = () => {
    const newUrl = `/booked?created_at=${watch(`startTimestamp_${id}`)}`;
    navigate(newUrl);
  };

  const redirectPay = () => {
    if (resultStats2?.isSuccess) {
      window.location.href = resultStats2?.data?.hostedUrl;
    }
  };

  const handleLogin = () => {
    // Navigate to the "/forgotPsw" route
    navigate('/login');
  };

  // eslint-disable-next-line no-return-assign
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={!resultStats2?.isSuccess ? handleCloseDialog : undefined}
        maxWidth="xs"
        fullWidth
      >
        <Card component={Paper}>
          {!resultStats2?.isSuccess && (
            <CardHeader
              action={
                <IconButton color="secondary" aria-label="close" onClick={handleCloseDialog}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              }
            />
          )}

          <CardContent>
            {resultStats5?.isLoading ? (
              <Typography variant="subtitle1" textAlign={'center'}>
                {'Loading'}
              </Typography>
            ) : resultStats5?.isError ? (
              <Typography variant="subtitle1" textAlign={'center'}>
                {resultStats5?.error?.data?.message}
                <br />
                <Link
                  color={'secondary'}
                  underline="hover"
                  component="button" // Use "button" instead of "routerLink"
                  onClick={handleLogin}
                >
                  Login
                </Link>{' '}
                at here
              </Typography>
            ) : (
              !verified && (
                <>
                  <Typography variant="subtitle1" textAlign={'center'} mb={2}>
                    Verification
                  </Typography>
                  {otpError && (
                    <Chip label={`Incorrect OTP please try again `} color="error" sx={{ mb: 2, width: '100%' }} />
                  )}

                  <Typography textAlign={'center'} mb={2}>
                    Code has sent to <br /> {watch(`email`)}
                  </Typography>
                  <Stack gap={2} flexDirection={'row'} justifyContent={'center'}>
                    {[...Array(6)].map((_, index) => (
                      <Box key={index}>
                        <TextField
                          variant="outlined"
                          type="number"
                          fullWidth
                          size="small"
                          inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                          value={otp[index] || ''}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          // eslint-disable-next-line no-return-assign
                          inputRef={(ref) => (inputRefs.current[index] = ref)}
                        />
                      </Box>
                    ))}
                  </Stack>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2 }}
                    color="primary"
                    onClick={handleSubmit((data) => onSubmit(data, false, true))}
                  >
                    Verify
                  </Button>

                  {resendCountdown === 0 ? (
                    <Typography textAlign="center" mt={2}>
                      Have not received the verification code?{' '}
                      <Typography
                        color="primary"
                        fontWeight="bold"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleSubmit((data) => onSubmit(data, true, false))}

                        // onClick={() => handleSubmit(onSubmit)}
                      >
                        Resend
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography textAlign="center" mt={2} variant="body2">
                      We've sent you again now you can resend code in {resendCountdown} seconds
                    </Typography>
                  )}
                </>
              )
            )}

            {verified && (
              <>
                {verified && <Chip label={`Verified : ${watch(`email`)}`} color="success" />}
                <Typography mt={0.5}>
                  {resultStats2?.isLoading ? (
                    <Typography my={2}>Loading...</Typography>
                  ) : resultStats2?.isSuccess ? (
                    <>
                      <Typography variant="h6" my={2}>
                        Your booking has been confirmed
                      </Typography>
                      <Typography variant="h6" mt={2}>
                        Payment Options
                      </Typography>
                      <Typography mt={1}>
                        How would you like to pay? Choose from the options below to proceed with your payment.
                      </Typography>

                      <Stack
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        mt={4}
                        gap={2}
                        flexWrap={'wrap'}
                      >
                        {stripe && (
                          <Button variant="contained" onClick={redirectPay}>
                            Pay now
                          </Button>
                        )}

                        <Button variant="contained" onClick={() => navigate('/')}>
                          Pay at space
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    resultStats2?.error && Object.values(resultStats2.error.data).find((error) => error.length > 0)
                  )}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Dialog>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '375px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Book ({room?.title})
          </Typography>
          <IconButton onClick={onCloseFilter} color="secondary">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 1 }}>
            <EnquiryForm
              errors={errors}
              register={register}
              trigger={trigger}
              meetingRoomId={id}
              watch={watch}
              control={control}
              uniqueId={id}
              setValue={setValue}
              type={bookingType}
              publicRoom={publicRoom}
              weekdays={weekdays}
              locationId={locationId}
              hotDeskDate={hotDeskDate}
            />
          </Stack>
        </Scrollbar>
        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown }}>
          <Box sx={{ px: 3, my: 2 }}>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              disabled={bookingType === 1 && (!watch(`slot_${id}`) || watch(`slot_${id}`)?.length <= 0)}
              loading={resultStats?.isLoading}
              variant="contained"
              fullWidth
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                {bookingType === 1 ? 'Book meeting room' : 'Book hotdesk'}
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>

      <BookingSummary
        open={openModal}
        loading={loader}
        handleClose={handleClose}
        onClick={handleSubmit(onSubmit)}
        capacity={room?.capacity}
        name={room?.title}
        price={room?.price}
        spaceType={room?.spaceType}
        startDate={watch(`startDate_${id}`)}
        start={watch(`startTimestamp_${id}`)}
        end={watch(`endTimestamp_${id}`)}
        type={bookingType}
        roomId={room.id}
        publicRoom={publicRoom}
        weekdays={weekdays}
        locationId={locationId}
        meetingRoomCurrency={meetingRoomCurrency}
        register={register}
        errors={errors}
        trigger={trigger}
        uniqueId={id}
        watch={watch}
        setValue={setValue}
        globalTaxRateObj={globalTaxRateObjPublic || globalTaxRateObj}
        currentGlobalTaxRateObj={currentGlobalTaxRateObjPublic || currentGlobalTaxRateObj}
        inclusiveTax={inclusiveTax}
        publicBillingOption={publicBillingOption}
        publicCreditPriceInclusiveTax={publicCreditPriceInclusiveTax}
      />

      <VisitorSummary
        open={openVisitorModal}
        loading={loader}
        handleClose={handleCloseVisitor}
        onClick={handleVisitor(onSubmitVisitor)}
        registerVisitor={registerVisitor}
        errorsVisitor={errorsVisitor}
        setValue={setValueVisitor}
        watch={watchVisitor}
        trigger={triggerVisitor}
      />

      <CustomAlert type={type ? 'success' : 'error'} message={message} open={open} setOpen={setOpen} />
    </>
  );
}
