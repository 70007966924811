import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Container,
  Dialog,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import dayjs from 'dayjs';
import SetCookie from '../utils/setCookie';
import { SPACELOGO, SPACENAME, THEMECOLOR } from '../Slices/spaceSlice';
import { formatDateNotTime } from '../utils/displayDateTime';
import { ProductCard } from '../components/product/ProductCard';
import ProductSummary from '../components/product/ProductSummary';
import EnquiryProduct from '../components/product/EnquiryProduct';
import { useGetProductsQuery, useUseGetDurationQuery, useViewHotDeskQuery } from '../Slices/spaceApi';
import Iconify from '../components/iconify';
import EnquiryCheck from '../components/Booking/EnquiryCheck';
import Loader from '../components/Loader/Loader';
import {
  useGetBrandsQuery,
  useGetProductBasedCategoryQuery,
  useGetProductCategoryQuery,
  useGetPublicMeetingRoomsQuery,
  useGetTourLocationsQuery,
} from '../Slices/constantApi';
import GetCookie from '../utils/getCookie';

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  '& .MuiTabs-indicatorSpan': {},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  '&.Mui-focusVisible': {},
}));

function BookingEnquiryPage() {
  const StyledContent = styled.div`
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    //   min-height: 100vh;
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 600px) {
      width: 90%;
    }

    @media (min-width: 960px) {
      width: 800px;
    }
  `;

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const Dispatch = useDispatch();

  const { companyName } = useParams();
  const [value, setValue] = useState(0);

  const { data: brandColors, isLoading } = useGetBrandsQuery(companyName);

  const navigate = useNavigate();
  const { currentCredentials, credentials, name, currentName, spaceLogo, currentSpaceLogo } = useSelector(
    (item) => item.spaceReducer
  );

  console.log(companyName);

  useEffect(() => {
    if ((!credentials || !currentCredentials) && brandColors) {
      SetCookie('themeColor', JSON.stringify(brandColors?.brandColors));
      SetCookie('spaceLogo', brandColors?.imageUrls?.[0]);
      SetCookie('spaceName', brandColors?.name);

      Dispatch(THEMECOLOR(brandColors?.brandColors));
      Dispatch(SPACELOGO(brandColors?.imageUrls?.[0]));
      Dispatch(SPACENAME(brandColors?.name));
    }
  }, [brandColors]);

  const { spaceId, currentSpaceId, currency, currentCurrency } = useSelector((item) => item.spaceReducer);

  const { data: locationsData, isLoading: toursLoading } = useGetTourLocationsQuery(companyName);

  const [publicSpace, setPublicSpace] = useState(locationsData?.[0]);

  const [week, setWeek] = useState(1);

  const { data: hotDesks, isFetching: hotDeskFetching } = useGetProductBasedCategoryQuery(
    { spaceId: publicSpace?.id },
    {
      skip: value === 0 || value === -1,
    }
  );

  const { data: hotDesksAvailablity, isLoading: hotdeskLoading } = useViewHotDeskQuery(
    {
      spaceId: publicSpace?.id,
      week,
    },
    {
      skip: !publicSpace?.id,
    }
  );

  const currentDate = new Date();
  const offsetInDays = (week - 1) * 7; // Calculate the offset in days based on the week
  currentDate.setDate(currentDate.getDate() + offsetInDays); // Set the currentDate based on the offset

  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7);

  const events = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + i);
    const availabilityObj = hotDesksAvailablity?.find((item) => item.date === formatDate(date));
    console.log(availabilityObj);
    const title = availabilityObj ? (availabilityObj.available ? 'Want to book?' : 'Not Available') : 'Not Available';
    return {
      title,
      start: date,
      available: availabilityObj?.available || false,
      count: availabilityObj?.count || 1,
    };
  });

  function formatDate(date) {
    // eslint-disable-next-line no-restricted-globals
    if (!(date instanceof Date && !isNaN(date))) {
      return 'Invalid Date';
    }

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }

  const { data: duration, isLoading: durationLoading } = useUseGetDurationQuery(
    { spaceId: publicSpace?.id },
    {
      skip: !publicSpace?.id,
    }
  );

  // const [openFilter, setOpenFilter] = useState(false);
  const [selectProductEnquiry, setselectProductEnquiry] = useState();
  const [checkoutProduct, setCheckoutProduct] = useState({ products: [], publicUser: {} });
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [openSummaryHotDeskModal, setOpenSummaryHotDeskModal] = useState(false);
  const [openProductFilter, setOpenProductFilter] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [spaceTypes, setSpaceTypes] = useState();
  const [selectedSpaceType, setSelectedSpaceType] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOtherType, setSelectedOtherType] = useState(null);
  const [openHotDeskDialog, setOpenHotDeskDialog] = useState(false);
  const [hotDeskDate, setHotDeskDate] = useState('');
  const [checkoutHotDesk, setCheckoutHotDesk] = useState({ products: [], publicUser: {} });
  const [selectHotDeskEnquiry, setSelectHotDeskEnquiry] = useState(false);
  const [openHotDeskFilter, setOpenHotDeskFilter] = useState(false);

  const handleCloseHotDeskDialog = () => {
    setOpenHotDeskDialog(false);
  };

  const { data: getCategories, isLoading: categoriesLoading } = useGetProductCategoryQuery(
    { spaceId: publicSpace?.id },
    {
      skip: !publicSpace?.id,
    }
  );

  const { data, isLoading: meetingRoomLoading } = useGetPublicMeetingRoomsQuery(publicSpace?.id, {
    skip: !publicSpace?.id,
  });

  useEffect(() => {
    if (data?.length > 0) {
      const filteredData = data.filter((item) => item?.spaceType !== null && item?.spaceType !== 19);

      const uniqueSpaces = Array.from(new Set(filteredData.map((item) => item.spaceTypeObj?.id))).map((id) => {
        const spaceData = filteredData.find((item) => item.spaceTypeObj?.id === id);

        return { id: spaceData.spaceTypeObj?.id, name: spaceData.spaceTypeObj?.name };
      });

      setSpaceTypes(uniqueSpaces);

      setSelectedSpaceType(uniqueSpaces?.[0]);
    }
  }, [data]);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleSummaryClose = () => {
    setOpenSummaryModal(false);
  };

  const handleSummaryHotDeskClose = () => {
    setOpenSummaryHotDeskModal(false);
  };

  const handleSummaryOpen = () => {
    setOpenSummaryModal(true);
  };

  const handleSummaryHotDeskOpen = () => {
    setOpenSummaryHotDeskModal(true);
  };

  const handleEnquiryProduct = (item) => {
    const existingIndex = checkoutProduct?.products.findIndex((product) => product.product === item.id);

    if (existingIndex !== -1) {
      const updatedProducts = [...checkoutProduct.products];
      updatedProducts.splice(existingIndex, 1);
      setCheckoutProduct((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        products: updatedProducts,
      }));
    } else {
      setOpenProductFilter(true);
      setselectProductEnquiry({ product: item });
    }
  };

  const handleEnquiryHotDesk = (item) => {
    const existingIndex = checkoutHotDesk?.products.findIndex((product) => product.product === item.id);

    if (existingIndex !== -1) {
      const updatedProducts = [...checkoutHotDesk.products];
      updatedProducts.splice(existingIndex, 1);
      setCheckoutHotDesk((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        products: updatedProducts,
      }));
    } else {
      setOpenHotDeskFilter(true);
      setSelectHotDeskEnquiry({ product: item });
    }
  };

  const handleCloseHotDeskFilter = () => {
    setOpenHotDeskFilter(false);
  };

  const { data: products, isFetching } = useGetProductsQuery(
    {
      spaceId: publicSpace?.id,
      activePage,
      page_size: 30,
      categoryType: selectedOtherType?.id,
    },
    {
      skip: !publicSpace?.id,
    }
  );

  // Remove the "Hot Desks" object from the array
  const newOtherTypes = getCategories?.categories.filter((item) => item.name !== 'Hot Desks');

  const [selectBookingEnquiry, setselectBookingEnquiry] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [type, setType] = useState({ title: 'Meeting Rooms', value: 1 });

  useEffect(() => {
    const sessionToken = GetCookie('session-token');

    if (currentCredentials || sessionToken) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setPublicSpace(locationsData?.[0]);
  }, [locationsData]);

  const handleEnquiry = (item) => {
    setOpenFilter(true);
    setselectBookingEnquiry({ room: item, id: item.id });
  };

  const [openFilter, setOpenFilter] = useState(false);

  const handleCloseFilter = () => {
    setType({ title: 'Meeting Rooms', value: 1 });
    setOpenFilter(false);
  };

  const handleCloseProductFilter = () => {
    setOpenProductFilter(false);
  };

  useEffect(() => {
    if (selectedSpaceType) {
      const filteredRooms = data?.filter((item) => {
        const spaceTypeCondition = !selectedSpaceType || item?.spaceTypeObj?.id === selectedSpaceType?.id;

        return spaceTypeCondition;
      });

      setFilteredData(filteredRooms);
    } else {
      setFilteredData(data);
    }
  }, [data, selectedSpaceType]);

  const handleEventClick = (clickInfo) => {
    if (clickInfo.event.extendedProps.available) {
      setOpenHotDeskDialog(true);
      setHotDeskDate(clickInfo.event.startStr);
    }
  };

  const nextWeek = () => {
    setWeek(week + 1);
  };

  const prevWeek = () => {
    setWeek(week - 1);
  };

  if (toursLoading) {
    return <Loader packageProp={companyName} />;
  }

  return (
    <>
      <Helmet>
        <title> {`Reserve your booking at ${companyName}`} </title>
      </Helmet>

      <Container>
        <Box sx={{ height: companyName ? '25px' : '' }} />

        {locationsData?.length > 0 ? (
          <>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Booking Enquiry
            </Typography>

            <StyledTabs
              value={value}
              onChange={handleChange}
              sx={{
                mb: 5,
                '& .MuiTabs-indicator': {
                  backgroundColor: (theme) => theme.palette.text.primary,
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '',
                  outline: 'none',
                  boxShadow: 'none',
                },
              }}
              aria-label="styled tabs example"
            >
              <StyledTab
                sx={{
                  p: 0,
                  mr: 3,
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.text.primary, // Active tab text color
                    fontWeight: 600,
                  },
                  fontWeight: 500,
                }}
                value={0}
                label={
                  <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                    Resources
                  </Typography>
                }
              />

              <StyledTab
                sx={{
                  p: 0,
                  mr: 3,
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.text.primary, // Active tab text color
                    fontWeight: 600,
                  },
                  fontWeight: 500,
                }}
                value={1}
                label={
                  <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                    Hot Desks
                  </Typography>
                }
              />

              <StyledTab
                sx={{
                  p: 0,
                  mr: 3,
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.text.primary, // Active tab text color
                    fontWeight: 600,
                  },
                  fontWeight: 500,
                }}
                value={-1}
                label={
                  <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                    Others
                  </Typography>
                }
              />
            </StyledTabs>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {locationsData?.length > 0 && publicSpace && (
                  <Autocomplete
                    options={locationsData}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={publicSpace}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setPublicSpace(newValue);
                      } else {
                        setPublicSpace(locationsData?.[0]);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Space Location" />}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {spaceTypes?.length > 0 && value === 0 ? (
                  <Autocomplete
                    options={spaceTypes}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={selectedSpaceType}
                    onChange={(event, newValue) => {
                      setSelectedSpaceType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Space Type" />}
                  />
                ) : newOtherTypes?.length > 0 && value === -1 ? (
                  <Autocomplete
                    options={newOtherTypes}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={selectedOtherType}
                    onChange={(event, newValue) => {
                      setSelectedOtherType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Type" />}
                  />
                ) : null}
              </Grid>
            </Grid>

            {value === 0 ? (
              <>
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" paragraph>
                      Resources
                    </Typography>
                  </Grid>
                  {meetingRoomLoading ? (
                    <Grid item xs={12} textAlign={'center'}>
                      <Typography variant="h6" paragraph>
                        Loading...
                      </Typography>
                    </Grid>
                  ) : filteredData?.length > 0 ? (
                    filteredData?.map((item, idx) => (
                      <Grid item md={4} xs={12} key={idx}>
                        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <CardMedia component="img" alt="" height="140" image={item.imageUrls} />
                          <CardContent style={{ flexGrow: 1 }}>
                            <Box>
                              <Typography variant="h6" mb={2}>
                                {item.title} (Capacity - {item.capacity})
                              </Typography>

                              <Typography variant="subtitle2" mb={1}>
                                {`Required ${parseInt(item?.price, 10).toFixed(2)} 
                                  ${item.space?.currency}
                                  `}
                              </Typography>
                              <Button variant="contained" onClick={() => handleEnquiry(item)}>
                                Resource Enquiry
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} textAlign={'center'}>
                      <Typography variant="h6" paragraph>
                        There's no resource with that filter
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : value === -1 ? (
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h6">Products</Typography>
                    <Button
                      variant="contained"
                      onClick={handleSummaryOpen}
                      disabled={checkoutProduct?.products?.length === 0}
                    >
                      Basket
                    </Button>
                  </Stack>
                </Grid>
                {isFetching ? (
                  <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h6" paragraph>
                      Loading...
                    </Typography>
                  </Grid>
                ) : products?.products?.length > 0 ? (
                  products?.products?.map((item, idx) => (
                    <ProductCard
                      key={idx}
                      item={item}
                      handleEnquiry={handleEnquiryProduct}
                      duration={duration}
                      currency={currency || publicSpace?.currency || 'PKR'}
                      currentCurrency={currentCurrency || publicSpace?.currency || 'PKR'}
                      checkoutProduct={checkoutProduct.products}
                      meetingRooms={data}
                    />
                  ))
                ) : (
                  <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h6" paragraph>
                      There's no product
                    </Typography>
                  </Grid>
                )}

                {products?.products?.length > 0 && !isFetching && (
                  <Box width={'100%'} mt={2}>
                    <Pagination
                      count={products?.totalPages}
                      color="primary"
                      page={activePage}
                      onChange={handlePageChange}
                      sx={{ '.MuiPagination-ul': { justifyContent: 'center', width: '100%' } }}
                    />
                  </Box>
                )}
              </Grid>
            ) : (
              <Grid container spacing={2} mt={3}>
                <Grid item xs={12}>
                  <Typography variant="h6">Hot Desks</Typography>
                </Grid>

                <Grid item xs={12}>
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGrid"
                    headerToolbar={false}
                    height={isMediumScreen ? 170 : 220} // Set height to 100% for responsiveness
                    events={events}
                    initialDate={currentDate}
                    visibleRange={{ start: currentDate, end: endDate }}
                    finalDate={endDate}
                    eventClick={handleEventClick}
                    dayHeaderContent={(arg) => (
                      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {arg.date.toLocaleDateString('en-US', { weekday: 'short' })}
                      </div>
                    )}
                    eventContent={(eventInfo) => (
                      <Chip
                        label={eventInfo.event.title}
                        color={eventInfo.event.extendedProps.available ? 'success' : 'error'}
                        sx={{
                          width: '100%',
                          cursor: eventInfo.event.extendedProps.available ? 'pointer' : 'default',
                        }}
                      />
                    )}
                    dayCellContent={(arg) => {
                      const availabilityObj = hotDesksAvailablity?.find((item) => item.date === formatDate(arg.date));
                      const isAvailable = availabilityObj ? availabilityObj.available : false;
                      const count = availabilityObj?.count;
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            mt: 2,
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Box>{arg.date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</Box>
                          <Typography color="primary">{`${count || ''} ${
                            count === 1 ? 'seat' : count === 0 ? 'no seat' : 'seats'
                          }`}</Typography>

                          {/* <Typography sx={{ color: (theme) => theme.palette.action.tertiaryColor }}>
                            {isMediumScreen
                              ? isAvailable
                                ? 'Available'
                                : 'Not Available'
                              : isAvailable
                              ? 'Avail...'
                              : 'Not Avail'}
                          </Typography> */}
                        </Box>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Button onClick={prevWeek} variant="contained" disabled={week === 1}>
                      Prev Week
                    </Button>

                    <Button onClick={nextWeek} variant="contained">
                      Next Week
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}

            <Box sx={{ height: companyName ? '25px' : '' }} />
          </>
        ) : (
          <StyledContent>
            <Box>Not a Valid Url</Box>
          </StyledContent>
        )}

        {selectBookingEnquiry && (
          <EnquiryCheck
            openFilter={openFilter}
            onCloseFilter={handleCloseFilter}
            {...selectBookingEnquiry}
            type={type?.value}
            weekdays={publicSpace?.weekdays}
            locationId={publicSpace?.id}
            publicRoom
            meetingRoomCurrency={selectBookingEnquiry?.room?.space?.currency}
            weekdaysTime={publicSpace?.openDays}
            inclusiveTax={selectBookingEnquiry?.room?.inclusiveTax}
            publicBillingOption={publicSpace?.billing_option}
            publicCreditPriceInclusiveTax={publicSpace?.publicCreditPriceInclusiveTax}
            globalTaxRateObj={
              publicSpace?.taxRateObj
                ? publicSpace?.taxRateObj
                : {
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  }
            }
            currentGlobalTaxRateObj={
              publicSpace?.taxRateObj
                ? JSON.stringify(publicSpace?.taxRateObj)
                : JSON.stringify({
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  })
            }
            stripe={publicSpace?.stripe}
          />
        )}

        {selectProductEnquiry && (
          <EnquiryProduct
            openFilter={openProductFilter}
            onCloseFilter={handleCloseProductFilter}
            {...selectProductEnquiry}
            currency={currency || publicSpace?.currency || 'PKR'}
            currentCurrency={currentCurrency || publicSpace?.currency || 'PKR'}
            setCheckoutProduct={setCheckoutProduct}
            checkoutProduct={checkoutProduct}
            publicRoom
            weekdaysData={publicSpace?.weekdays}
          />
        )}

        <Dialog open={openHotDeskDialog} sx={{ zIndex: 1 }} onClose={handleCloseHotDeskDialog} maxWidth="md" fullWidth>
          <Card component={Paper} sx={{ overflowY: 'auto', zIndex: 1 }}>
            <CardHeader
              title={`Want to book hotdesk on ${formatDateNotTime(dayjs(hotDeskDate))}?`}
              action={
                <IconButton color="secondary" aria-label="close" onClick={handleCloseHotDeskDialog}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              }
            />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography>Which package would you like to take?</Typography>
                    <Button
                      variant="contained"
                      onClick={handleSummaryHotDeskOpen}
                      disabled={checkoutHotDesk?.products?.length === 0}
                    >
                      Basket
                    </Button>
                  </Stack>
                </Grid>

                {hotDeskFetching ? (
                  <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h6" paragraph>
                      Loading...
                    </Typography>
                  </Grid>
                ) : hotDesks?.products?.length > 0 ? (
                  hotDesks?.products?.map((item, idx) => (
                    <ProductCard
                      key={idx}
                      item={item}
                      handleEnquiry={handleEnquiryHotDesk}
                      duration={duration}
                      currency={currency || publicSpace?.currency || 'PKR'}
                      currentCurrency={currentCurrency || publicSpace?.currency || 'PKR'}
                      checkoutProduct={checkoutHotDesk.products}
                      meetingRooms={data}
                      hotDesk
                    />
                  ))
                ) : (
                  <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h6" paragraph>
                      There's no package
                    </Typography>
                  </Grid>
                )}
              </Grid>

              {hotDesks?.products?.length > 0 && !hotDeskFetching && (
                <Box width={'100%'} mt={2}>
                  <Pagination
                    count={hotDesks?.totalPages}
                    color="primary"
                    page={activePage}
                    onChange={handlePageChange}
                    sx={{ '.MuiPagination-ul': { justifyContent: 'center', width: '100%' } }}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Dialog>

        {selectHotDeskEnquiry && (
          <EnquiryProduct
            hotDesk
            openFilter={openHotDeskFilter}
            onCloseFilter={handleCloseHotDeskFilter}
            {...selectHotDeskEnquiry}
            currency={currency || publicSpace?.currency || 'PKR'}
            currentCurrency={currentCurrency || publicSpace?.currency || 'PKR'}
            setCheckoutProduct={setCheckoutHotDesk}
            checkoutProduct={checkoutHotDesk}
            publicRoom
            hotDeskDate={hotDeskDate}
            weekdaysData={publicSpace?.weekdays}
            duration={duration}
          />
        )}

        <ProductSummary
          products={checkoutHotDesk}
          open={openSummaryHotDeskModal}
          handleClose={handleSummaryHotDeskClose}
          currency={currency || publicSpace?.currency || 'PKR'}
          currentCurrency={currentCurrency || publicSpace?.currency || 'PKR'}
          quantity={false}
          locationId={publicSpace?.id}
          setProducts={setCheckoutHotDesk}
          currentGlobalTaxRateObj={JSON.stringify({
            rentalFee: publicSpace?.rentalFee,
            serviceCharges: publicSpace?.serviceCharges,
            setOveralTax: publicSpace?.setOveralTax,
          })}
          globalTaxRateObj={{
            rentalFee: publicSpace?.rentalFee,
            serviceCharges: publicSpace?.serviceCharges,
            setOveralTax: publicSpace?.setOveralTax,
          }}
          publicRoom
          hotDesk
          hotDeskDate={hotDeskDate}
          stripe={publicSpace?.stripe}
        />

        <ProductSummary
          products={checkoutProduct}
          open={openSummaryModal}
          handleClose={handleSummaryClose}
          currency={currency || publicSpace?.currency || 'PKR'}
          currentCurrency={currentCurrency || publicSpace?.currency || 'PKR'}
          quantity={false}
          locationId={publicSpace?.id}
          setProducts={setCheckoutProduct}
          currentGlobalTaxRateObj={JSON.stringify({
            rentalFee: publicSpace?.rentalFee,
            serviceCharges: publicSpace?.serviceCharges,
            setOveralTax: publicSpace?.setOveralTax,
          })}
          globalTaxRateObj={{
            rentalFee: publicSpace?.rentalFee,
            serviceCharges: publicSpace?.serviceCharges,
            setOveralTax: publicSpace?.setOveralTax,
          }}
          publicRoom
          stripe={publicSpace?.stripe}
        />
      </Container>
    </>
  );
}

export default BookingEnquiryPage;
