import dayjs from 'dayjs';

export const getNextOpenDate = (openDays, currentOpenDays) => {
  console.log(openDays, currentOpenDays, 'khani?');
  const currentDate = dayjs();
  const weekdays = openDays?.length > 0 ? openDays : currentOpenDays?.length > 0 ? JSON.parse(currentOpenDays) : [];

  const nextOpenDay = weekdays
    ?.map((day) => (day.day === 7 ? 0 : day.day)) // Convert Sunday (7) to 0
    .filter((day) => day >= currentDate.day()) // Filter days greater than or equal to the current day
    .sort((a, b) => a - b)[0]; // Sort and get the first (smallest) day

  const nextOpenDate =
    nextOpenDay !== undefined
      ? currentDate.day(nextOpenDay)
      : weekdays.length > 0
      ? currentDate.add(1, 'week').startOf('week').day(weekdays[0].day)
      : null;

  return nextOpenDate;
};
