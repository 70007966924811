import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import {
  BOOKINGCONFIGURATION,
  CREDITPRICEINCLUSIVETAX,
  CURRENCY,
  OPENDAYS,
  OPENDAYSTIME,
  SPACEBILLINGOPTIONS,
  SPACECREDS,
  SPACEID,
  SPACELOGO,
  SPACENAME,
  SPACEUSERID,
  STRIPE,
  TAXRATEOBJ,
  THEMECOLOR,
  USERTYPE,
} from '../../../Slices/spaceSlice';
import SetCookie from '../../../utils/setCookie';
import Iconify from '../../../components/iconify';
import { CustomAlert } from '../../../utils/Alert';

export default function LoginForm() {
  const Dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger, // Import the trigger function
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const handleForgotPasswordClick = () => {
    // Navigate to the "/forgotPsw" route
    navigate('/forgotPsw');
  };

  const onSubmit = async (data) => {
    setLoading(true);

    // Manually trigger validation for all fields before submitting
    const isFormValid = await trigger();

    if (isFormValid) {
      axios
        .post('/login/', data)
        .then((response) => {
          if (response?.data?.userType === 'owner') {
            window.location.href = 'https://portal.ndesks.com/login';
            return;
          }

          setType(true);
          setOpen(true);
          setMessage("You've successfully logged in.");
          setLoading(false);
          SetCookie('token', response?.data?.['login-token'].token);
          SetCookie('userId', response?.data?.userId);
          SetCookie('spaceId', response?.data?.Spaces?.[0]);
          SetCookie('creds-member', response?.data?.creds);
          SetCookie('billing_option', response?.data?.billing_option);
          SetCookie('currency', response?.data?.currency);
          SetCookie('openDays', JSON.stringify(response?.data?.openDays));
          SetCookie('spaceLogo', response?.data?.imageUrls);
          SetCookie('spaceName', response?.data?.name);
          SetCookie('booking_configuration', response?.data?.booking_configuration);
          SetCookie('openDaysTime', JSON.stringify(response?.data?.openDaysTime));
          SetCookie('userType', response?.data?.userType);

          SetCookie(
            'taxRateObj',
            JSON.stringify(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : JSON.stringify({
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  })
            )
          );

          SetCookie('creditPriceInclusiveTax', response?.data.creditPriceInclusiveTax || true);

          SetCookie('stripe', response?.data?.stripe);
          Dispatch(STRIPE(response?.data?.stripe));

          const brandColors = response?.data?.brandColors;

          SetCookie('themeColor', JSON.stringify(brandColors));

          Dispatch(THEMECOLOR(brandColors));
          Dispatch(CURRENCY(response?.data?.currency));
          Dispatch(SPACECREDS(response?.data?.creds));
          Dispatch(SPACEUSERID(response?.data?.userId));
          Dispatch(SPACEID(response?.data?.Spaces?.[0]));
          Dispatch(SPACEBILLINGOPTIONS(response?.data?.billing_option));
          Dispatch(OPENDAYS(response?.data?.openDays));
          Dispatch(SPACELOGO(response?.data?.imageUrls));
          Dispatch(SPACENAME(response?.data?.name));
          Dispatch(OPENDAYSTIME(response?.data?.openDaysTime));
          Dispatch(USERTYPE(response?.data?.userType));
          Dispatch(CREDITPRICEINCLUSIVETAX(response?.data.creditPriceInclusiveTax || true));
          Dispatch(BOOKINGCONFIGURATION(response?.data?.booking_configuration));

          Dispatch(
            TAXRATEOBJ(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : {
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  }
            )
          );

          setTimeout(() => {
            navigate('/', { replace: true });
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setType(false);
          setOpen(true);
          setMessage(error.response.data.message || error.response.data.error);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address format',
            },
          }}
          render={({ field }) => (
            <TextField
              label="Email address"
              {...field}
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : ''}
              onBlur={() => trigger('email')} // Trigger validation onBlur
              onKeyPress={handleKeyPress}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: 'Password is required' }}
          render={({ field }) => (
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="secondary" onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...field}
              onKeyPress={handleKeyPress}
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.message : ''}
              onBlur={() => trigger('password')} // Trigger validation onBlur
            />
          )}
        />
      </Stack>

      <Box sx={{ my: 2 }}>
        <Link
          color={'secondary'}
          variant="subtitle2"
          underline="hover"
          sx={{ float: 'right' }}
          component="button" // Use "button" instead of "routerLink"
          onClick={handleForgotPasswordClick}
        >
          Forgot password?
        </Link>
      </Box>

      <LoadingButton
        loading={loading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit(onSubmit)}
      >
        Login
      </LoadingButton>
      <CustomAlert type={type ? 'success' : 'error'} message={message} open={open} setOpen={setOpen} />
    </>
  );
}
