// const { convertToTimestamp } = require('./convertDateTimeToTimeStamp');

export function calculateDuration(startTimestamp, endTimestamp) {
  const durationInSeconds = endTimestamp - startTimestamp;
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  return `${hours} hours ${minutes} minutes`;
}

// Function to format and parse the date and time
export function calDuration(startDate, startTime, endTime) {
  const startTimestamp = convertToTimestamp(`${startDate}T${startTime}`);
  const endTimestamp = convertToTimestamp(`${startDate}T${endTime}`);

  // Calculate the duration
  const duration = calculateDuration(startTimestamp, endTimestamp);

  // Format and return the parsed date and duration
  return `(${duration})`;
}

const { convertToTimestamp } = require('./convertDateTimeToTimeStamp');

// Function to format and parse the date and time
export function parseDateTime(startDate, startTime, endTime) {
  const startTimestamp = convertToTimestamp(`${startDate}T${startTime}`);
  const endTimestamp = convertToTimestamp(`${startDate}T${endTime}`);
  const currentTimestamp = Math.floor(Date.now() / 1000); // Get current timestamp in seconds

  // Calculate the time remaining until the booking starts
  const timeRemainingInSeconds = startTimestamp - currentTimestamp;
  const hours = Math.floor(timeRemainingInSeconds / 3600);
  const minutes = Math.floor((timeRemainingInSeconds % 3600) / 60);

  // If the booking has already started or is ongoing, display "Now"
  if (timeRemainingInSeconds <= 0) {
    return '(Now)';
  }

  // If the booking starts within the next hour, display minutes remaining
  if (hours === 0) {
    return `(${minutes}m remaining)`;
  }

  // Display hours and minutes remaining until the booking starts
  return `(${hours}h ${minutes}m remaining)`;
}

export function updateSlot(startTimestamp, endTimestamp) {
  const slots = [];
  const interval = 30 * 60; // 30 minutes in seconds

  for (let timestamp = startTimestamp; timestamp < endTimestamp; timestamp += interval) {
    const startDateTime = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const endDateTime = new Date((timestamp + interval) * 1000); // Add interval for end time

    const pad = (num) => (num < 10 ? `0${num}` : num);
    const slotStart = `${pad(startDateTime.getHours())}:${pad(startDateTime.getMinutes())}:${pad(
      startDateTime.getSeconds()
    )}`;
    const slotEnd = `${pad(endDateTime.getHours())}:${pad(endDateTime.getMinutes())}:${pad(endDateTime.getSeconds())}`;

    const slot = {
      start: slotStart,
      end: slotEnd,
    };

    slots.push(slot);
  }

  return slots;
}

export function updateTime(startDateTime) {
  const formattedStartDate = startDateTime.split('T')[0];
  return formattedStartDate;
}
