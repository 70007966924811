import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { formatDateTime } from '../../utils/formatDateTime';
import Iconify from '../iconify/Iconify';

const VisitForm = ({
  categories,
  setValue,
  register,
  errors,
  trigger,
  watch,
  loading,
  isEdit,
  id,
  handleMouseEnter,
  handleFileChange,
  handleMouseLeave,
  hovered,
}) => {
  const openInput = () => {
    document.getElementById('ali').click(); // Trigger the file input when the image container is clicked
  };

  const openInput1 = () => {
    document.getElementById(`ali_${id}`).click(); // Trigger the file input when the image container is clicked
  };

  return (
    <Grid container spacing={2}>
      {(watch('mainCategory') || watch(`mainCategory_${id}`)) && (watch('category') || watch(`category_${id}`)) && (
        <Grid item xs={12} md={!isEdit ? 4 : 12}>
          {!isEdit ? (
            <Card sx={{ padding: 3 }}>
              <Box mb={3} sx={{ textAlign: 'center' }}>
                <Box
                  sx={{
                    padding: '8px',
                    border: '1px dashed rgba(145, 158, 171, 0.2)',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    width: '144px',
                    height: '144px',
                    cursor: 'pointer',
                    marginX: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: '50%',
                      overflow: 'hidden',
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={openInput}
                  >
                    <img
                      src={
                        watch('file') instanceof File ? URL.createObjectURL(watch('file')) : '/assets/placeholder.svg'
                      }
                      alt="Uploaded Avatar"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    {(hovered || !(watch('file') instanceof File)) && (
                      <>
                        <Box
                          style={{
                            top: '0',
                            position: 'absolute',
                            left: '0',
                            backgroundColor: 'rgb(0 0 0 / 50%)',
                            color: 'white',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <Box
                            component={'span'}
                            sx={{
                              top: '50%',
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50% , -50%)',
                            }}
                          >
                            <Iconify icon="bxs:camera-plus" />
                            <Typography component={'span'} sx={{ textWrap: 'nowrap' }} variant="caption">
                              Update photo
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
                <Box mt={0.5}>
                  <input
                    type="file"
                    accept=".jpeg, .jpg, .png , .webp"
                    id="ali"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </Box>
                <Typography variant="caption" mt={3} sx={{ color: (theme) => theme.palette.text.disabled }}>
                  Allowed *.jpeg, *.jpg, *.png , *.webp
                  <br /> max size of 3.1 MB
                </Typography>
              </Box>
            </Card>
          ) : (
            <Box sx={{ padding: 3 }}>
              <Box sx={{ textAlign: 'center' }}>
                <Box
                  sx={{
                    padding: '8px',
                    border: '1px dashed rgba(145, 158, 171, 0.2)',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    width: '144px',
                    height: '144px',
                    cursor: 'pointer',
                    marginX: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: '50%',
                      overflow: 'hidden',
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={openInput1}
                  >
                    <img
                      src={
                        watch(`file_${id}`) instanceof File
                          ? URL.createObjectURL(watch(`file_${id}`))
                          : watch(`attach_photo_${id}`) || '/assets/placeholder.svg'
                      }
                      alt="Uploaded Avatar"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    {(hovered || (watch(`file_${id}`) instanceof File ? false : !watch(`attach_photo_${id}`))) && (
                      <>
                        <Box
                          style={{
                            top: '0',
                            position: 'absolute',
                            left: '0',
                            backgroundColor: 'rgb(0 0 0 / 50%)',
                            color: 'white',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <Box
                            component={'span'}
                            sx={{
                              top: '50%',
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50% , -50%)',
                            }}
                          >
                            <Iconify icon="bxs:camera-plus" />
                            <Typography component={'span'} sx={{ textWrap: 'nowrap' }} variant="caption">
                              Update photo
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
                <Box mt={0.5}>
                  <input
                    type="file"
                    accept=".jpeg, .jpg, .png ,.webp"
                    id={`ali_${id}`}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                </Box>
                <Typography variant="caption" mt={3} sx={{ color: (theme) => theme.palette.text.disabled }}>
                  Allowed *.jpeg, *.jpg, *.png , *.webp
                  <br /> max size of 3.1 MB
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      )}

      <Grid item xs={12} md={isEdit ? 12 : !isEdit && watch('mainCategory') && watch('category') ? 8 : 12}>
        {categories?.length <= 0 ? (
          <Card sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={categories}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => {
                    setValue('mainCategory', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Main Category"
                      fullWidth
                      {...register('mainCategory', { required: 'Main Category is required' })}
                      error={!!errors.mainCategory}
                      helperText={errors.mainCategory ? errors.mainCategory.message : ''}
                      onBlur={() => trigger('mainCategory')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} textAlign={'center'}>
                <Typography variant="h6" paragraph>
                  There's no categories
                </Typography>

                <Typography variant="body2">
                  No results found for categories &nbsp;
                  <br /> Try to add before add ticket
                </Typography>
              </Grid>

              {!watch('mainCategory') && !watch('category') && (
                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    sx={{ float: 'right' }}
                    style={{ marginTop: '20px' }}
                    type="submit"
                  >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                      Add
                    </Stack>
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Card>
        ) : !isEdit ? (
          <Card sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  options={categories}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => {
                    setValue('mainCategory', newValue);
                    setValue('category', newValue?.children?.[0]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Main Category"
                      fullWidth
                      {...register('mainCategory', { required: 'Main Category is required' })}
                      error={!!errors.mainCategory}
                      helperText={errors.mainCategory ? errors.mainCategory.message : ''}
                      onBlur={() => trigger('mainCategory')}
                    />
                  )}
                />
              </Grid>

              {watch('mainCategory') && watch('category') && (
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={watch('mainCategory')?.children}
                    getOptionLabel={(option) => option.name}
                    value={watch('category')}
                    onChange={(_, newValue) => {
                      setValue('category', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        fullWidth
                        {...register('category', { required: 'Category is required' })}
                        error={!!errors.category}
                        helperText={errors.category ? errors.category.message : ''}
                        onBlur={() => trigger('category')}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                {watch('mainCategory') && watch('category') && (
                  <Typography variant="subtitle1" display={'flex'} alignItems={'center'}>
                    Name : &nbsp; <Typography variant="body2">{watch('category').name}</Typography>
                  </Typography>
                )}
              </Grid>

              {(!watch('mainCategory') || !watch('category')) && (
                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    sx={{ float: 'right' }}
                    style={{ marginTop: '20px' }}
                    type="submit"
                  >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                      Create Ticket
                    </Stack>
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Card>
        ) : (
          <Box sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {categories && categories?.length > 0 && (
                  <Autocomplete
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    value={watch(`mainCategory_${id}`)}
                    onChange={(_, newValue) => {
                      setValue(`mainCategory_${id}`, newValue);
                      setValue(`category_${id}`, newValue?.children?.[0]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Main Category"
                        fullWidth
                        {...register(`mainCategory_${id}`, { required: 'Main Category is required' })}
                        error={!!errors[`mainCategory_${id}`]}
                        helperText={errors[`mainCategory_${id}`] ? errors[`mainCategory_${id}`].message : ''}
                        onBlur={() => trigger(`mainCategory_${id}`)}
                      />
                    )}
                  />
                )}
              </Grid>

              {watch(`mainCategory_${id}`) && watch(`category_${id}`) && (
                <Grid item xs={12}>
                  <Autocomplete
                    options={watch(`mainCategory_${id}`)?.children}
                    getOptionLabel={(option) => option.name}
                    value={watch(`category_${id}`)}
                    onChange={(_, newValue) => {
                      setValue(`category_${id}`, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        fullWidth
                        {...register(`category_${id}`, { required: 'Category is required' })}
                        error={!!errors[`category_${id}`]}
                        helperText={errors[`category_${id}`] ? errors[`category_${id}`].message : ''}
                        onBlur={() => trigger(`category_${id}`)}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                {watch(`mainCategory_${id}`) && watch(`category_${id}`) && (
                  <Typography variant="subtitle1" display={'flex'} alignItems={'center'}>
                    Name : &nbsp; <Typography variant="body2">{watch(`category_${id}`).name}</Typography>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {watch('mainCategory') && watch('category') && !isEdit ? (
          <Card sx={{ padding: 3, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Purpose"
                  fullWidth
                  {...register('purpose', { required: 'Purpose is required' })}
                  error={!!errors.purpose}
                  helperText={errors.purpose ? errors.purpose.message : ''}
                  onBlur={() => trigger('purpose')}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={5}
                  {...register('description', { required: 'Description is required' })}
                  error={!!errors.description}
                  helperText={errors.description ? errors.description.message : ''}
                  onBlur={() => trigger('description')}
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ float: 'right' }}
                  style={{ marginTop: '20px' }}
                  type="submit"
                >
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                    Create Ticket
                  </Stack>
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        ) : watch(`mainCategory_${id}`) && watch(`category_${id}`) && isEdit ? (
          <Box sx={{ padding: 3, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Purpose"
                  fullWidth
                  {...register(`purpose_${id}`, { required: 'Purpose is required' })}
                  error={!!errors[`purpose_${id}`]}
                  helperText={errors[`purpose_${id}`] ? errors[`purpose_${id}`].message : ''}
                  onBlur={() => trigger(`purpose_${id}`)}
                />
              </Grid>

              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={5}
                  {...register(`description_${id}`, { required: 'Description is required' })}
                  error={!!errors[`description_${id}`]}
                  helperText={errors[`description_${id}`] ? errors[`description_${id}`].message : ''}
                  onBlur={() => trigger(`description_${id}`)}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default VisitForm;
