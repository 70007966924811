import { useState } from 'react';
// @mui
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RemoveCookie from '../../../utils/removeCookie';
import { useGetUserDetailsQuery } from '../../../Slices/spaceApi';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    path: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    path: '/user/profile',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    path: '/user/account',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { currentUserId, userId } = useSelector((item) => item.spaceReducer);
  const { data } = useGetUserDetailsQuery(userId || currentUserId);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    RemoveCookie('spaceId');
    RemoveCookie('token');
    RemoveCookie('creds-member');
    RemoveCookie('billing_option');
    RemoveCookie('userId');
    RemoveCookie('currency');
    RemoveCookie('themeColor');
    RemoveCookie('openDays');
    RemoveCookie('spaceName');
    RemoveCookie('booking_configuration');
    RemoveCookie('spaceLogo');
    RemoveCookie('userType');
    RemoveCookie('taxRateObj');
    RemoveCookie('creditPriceInclusiveTax');
    RemoveCookie('email');
    RemoveCookie('stripe');

    window.location.reload();
  };

  return (
    <>
      <Box
        sx={{
          padding: '3px',
          border: '1px dashed rgba(145, 158, 171, 0.2)',
          borderRadius: '50%',
          overflow: 'hidden',
          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        <IconButton
          color="secondary"
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',

                // bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Avatar
            src={data?.profileImg || '/assets/placeholder.svg'}
            alt="photoURL"
            onError={(e) => {
              e.target.src = '/assets/placeholder.svg'; // Set the placeholder on error
            }}
          />
        </IconButton>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {data?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {data?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} component={RouterLink} to={option.path}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
