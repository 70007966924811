/* eslint-disable camelcase */
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSendOtpMutation } from '../../Slices/constantApi';
import SetCookie from '../../utils/setCookie';
import {
  BOOKINGCONFIGURATION,
  CREDITPRICEINCLUSIVETAX,
  CURRENCY,
  EMAIL,
  OPENDAYS,
  OPENDAYSTIME,
  SPACEBILLINGOPTIONS,
  SPACECREDS,
  SPACEID,
  SPACELOGO,
  SPACENAME,
  SPACEUSERID,
  STRIPE,
  TAXRATEOBJ,
  THEMECOLOR,
  USERTYPE,
} from '../../Slices/spaceSlice';
import { CustomAlert } from '../../utils/Alert';
import { useGetDiscountsQuery, usePurchaseProductMutation } from '../../Slices/spaceApi';
import Iconify from '../iconify/Iconify';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ProductSummary = ({
  handleClose,
  open,
  products: { products, publicUser },
  currency,
  hotDesk,
  currentCurrency,
  setProducts,
  globalTaxRateObj,
  currentGlobalTaxRateObj,
  publicRoom,
  locationId,
  hotDeskDate,
  stripe,
}) => {
  const { spaceId, currentSpaceId, userId, currentUserId, publicEmail, currentPublicEmail } = useSelector(
    (item) => item.spaceReducer
  );

  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const [discount, setDiscount] = useState('');
  const [activeCode, setActiveCode] = useState('');

  const [purchaseProducts, resultStats] = usePurchaseProductMutation();
  const { data, isLoading, isError, isSuccess, error, isFetching } = useGetDiscountsQuery(
    {
      spaceId: publicRoom ? locationId : spaceId || currentSpaceId,
      code: discount,
    },
    {
      skip: publicRoom ? !locationId || !discount : !discount,
    }
  );

  const [loading, setLoading] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [type, setType] = useState(false);
  const [message, setMessage] = useState('');
  const [otp, setOTP] = useState('');
  const [verified, setVerified] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  console.log(products, 'products');

  const inputRefs = useRef([]);

  const [resendCountdown, setResendCountdown] = useState(0);

  const handleInputChange = (index, value) => {
    const newOTP = otp.substr(0, index) + value + otp.substr(index + 1);
    setOTP(newOTP);

    // Move focus to the next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const [sendOtp, resultStats5] = useSendOtpMutation();

  const onClick = async (resend, verify) => {
    if (publicRoom) {
      const { name, email, phone_number } = publicUser;
      if (!dialogOpen) {
        setDialogOpen(true);

        if (publicEmail === email || currentPublicEmail === email) {
          setOTP('');
          setVerified(true);
          setOtpError(false);

          const startOfDay = dayjs(hotDeskDate).startOf('day');
          const endOfDay = dayjs(hotDeskDate).endOf('day');

          const data = {
            items: products.map((item) => ({
              quantity: item.quantity,
              product: item.product,
              startDate: dayjs(item?.startDate).format('YYYY-MM-DD'),
            })),
            member: +userId || +currentUserId,
            ...(hotDesk && {
              booking: {
                capacity: 1,
                startTime: startOfDay.add(1, 'minute').unix(), // 12:01 am
                endTime: endOfDay.subtract(1, 'minute').unix(), // 11:59 pm
                member: +userId || +currentUserId,
                meetingRoom: null,
                type: 2,
              },
            }),
          };

          if (activeCode?.length >= 1) {
            data.discountCode = activeCode;
          }

          purchaseProducts({
            spaceId: locationId,
            data,
          });

          return;
        }

        sendOtp({
          spaceId: locationId,
          data: {
            name,
            email,
            phone_number,
          },
        });
      } else if (resend) {
        setResendCountdown(59);

        sendOtp({
          spaceId: locationId,
          data: {
            name,
            email,
            phone_number,
          },
        });
      } else if (verify) {
        try {
          const response = await axios.post(`/space/${locationId}/profile/verifyOtp/`, {
            otp,
            email,
          });

          const startOfDay = dayjs(hotDeskDate).startOf('day');
          const endOfDay = dayjs(hotDeskDate).endOf('day');

          const data = {
            items: products.map((item) => ({
              quantity: item.quantity,
              product: item.product,
              startDate: dayjs(item?.startDate).format('YYYY-MM-DD'),
            })),
            member: +response?.data?.userId,
            ...(hotDesk && {
              booking: {
                capacity: 1,
                startTime: startOfDay.add(1, 'minute').unix(), // 12:01 am
                endTime: endOfDay.subtract(1, 'minute').unix(), // 11:59 pm
                member: +response?.data?.userId,
                meetingRoom: null,
                type: 2,
              },
            }),
          };

          if (activeCode?.length >= 1) {
            data.discountCode = activeCode;
          }

          SetCookie('token', response?.data?.['login-token'].token);
          SetCookie('userId', response?.data?.userId);
          SetCookie('spaceId', response?.data?.Spaces?.[0]);
          SetCookie('creds-member', response?.data?.creds);
          SetCookie('billing_option', response?.data?.billing_option);
          SetCookie('currency', response?.data?.currency);
          SetCookie('openDays', JSON.stringify(response?.data?.openDays));
          SetCookie('spaceLogo', response?.data?.imageUrls);
          SetCookie('spaceName', response?.data?.name);
          SetCookie('booking_configuration', response?.data?.booking_configuration);
          SetCookie('openDaysTime', JSON.stringify(response?.data?.openDaysTime));
          SetCookie('userType', response?.data?.userType);
          SetCookie('email', response?.data?.email);

          SetCookie(
            'taxRateObj',
            JSON.stringify(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : JSON.stringify({
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  })
            )
          );

          SetCookie('stripe', response?.data?.stripe);
          Dispatch(STRIPE(response?.data?.stripe));

          SetCookie('creditPriceInclusiveTax', response?.data.creditPriceInclusiveTax || true);

          const brandColors = response?.data?.brandColors;

          SetCookie('themeColor', JSON.stringify(brandColors));

          Dispatch(THEMECOLOR(brandColors));

          Dispatch(CURRENCY(response?.data?.currency));
          Dispatch(EMAIL(response?.data?.email));

          Dispatch(SPACECREDS(response?.data?.creds));
          Dispatch(SPACEUSERID(response?.data?.userId));
          Dispatch(SPACEID(response?.data?.Spaces?.[0]));
          Dispatch(SPACEBILLINGOPTIONS(response?.data?.billing_option));
          Dispatch(OPENDAYS(response?.data?.openDays));
          Dispatch(SPACELOGO(response?.data?.imageUrls));
          Dispatch(SPACENAME(response?.data?.name));
          Dispatch(OPENDAYSTIME(response?.data?.openDaysTime));
          Dispatch(USERTYPE(response?.data?.userType));
          Dispatch(CREDITPRICEINCLUSIVETAX(response?.data.creditPriceInclusiveTax || true));
          Dispatch(BOOKINGCONFIGURATION(response?.data?.booking_configuration));

          Dispatch(
            TAXRATEOBJ(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : {
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  }
            )
          );

          setVerified(true);
          setOtpError(false);

          purchaseProducts({
            spaceId: locationId,
            data,
          });
        } catch (error) {
          setOTP('');
          setVerified(false);
          setOtpError(true);
        }
      }
    } else {
      const startOfDay = dayjs(dayjs(products?.[0]?.startDate).format('YYYY-MM-DD')).startOf('day');
      const endOfDay = dayjs(dayjs(products?.[0]?.startDate).format('YYYY-MM-DD')).endOf('day');

      const { name } = publicUser;

      const data = {
        items: products.map((item) => ({
          quantity: item.quantity,
          product: item.product,
          startDate: dayjs(item?.startDate).format('YYYY-MM-DD'),
        })),
        member: +userId || +currentUserId,
        ...(name && {
          booking: {
            capacity: 1,
            startTime: startOfDay.add(1, 'minute').unix(), // 12:01 am
            endTime: endOfDay.subtract(1, 'minute').unix(), // 11:59 pm
            member: +userId || +currentUserId,
            meetingRoom: null,
            type: 2,
          },
        }),
      };

      if (activeCode?.length >= 1) {
        data.discountCode = activeCode;
      }

      purchaseProducts({
        spaceId: spaceId || currentSpaceId,
        data,
      });
    }
  };

  // Countdown timer for resend functionality
  useEffect(() => {
    let intervalId;
    if (resendCountdown > 0) {
      intervalId = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendCountdown]);

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setLoading(false);
      setType(true);
      setOpenAlert(true);
      setMessage('Products purchased successfully.');
      setProducts((prev) => ({ ...prev, products: [] }));

      if (!publicRoom) {
        navigate(`/invoice/${resultStats?.data?.invoiceId}`);
      }

      handleClose();
      setActiveCode('');
      setDiscount('');
    } else if (resultStats?.isError) {
      setLoading(false);
      setType(false);
      setOpenAlert(true);
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessage(errorMessage);
    }
  }, [resultStats]);

  useEffect(() => {
    setActiveCode(discount);
  }, [discount]);

  const totalPrice = products.reduce(
    (acc, product) => acc + (product.quantity > 0 ? product.quantity * product.price : 1 * product.price),
    0
  );

  const totalTax = products.reduce((acc, product) => {
    const price = product.quantity > 0 ? product.quantity * product.price : 1 * product.price;
    if (product.taxRateObj && !product.taxRateObj.setOverallTax && !product?.inclusiveTax) {
      acc += (price * (product.taxRateObj.serviceCharges + product.taxRateObj.rentalFee)) / 100;
    } else if (product.taxRateObj && product.taxRateObj.setOverallTax) {
      acc += (price * product.taxRateObj.serviceCharges) / 100;
    } else if (
      !product?.taxRateObj &&
      (globalTaxRateObj?.setOverallTax ||
        (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)) &&
      !product?.inclusiveTax
    ) {
      acc +=
        (price *
          (globalTaxRateObj?.serviceCharges ||
            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges))) /
        100;
    } else if (
      !product?.taxRateObj &&
      (!globalTaxRateObj?.setOverallTax ||
        !(currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)) &&
      !product?.inclusiveTax
    ) {
      acc +=
        (price *
          ((globalTaxRateObj?.serviceCharges ||
            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) +
            (globalTaxRateObj?.rentalFee ||
              (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)))) /
        100;
    } else {
      acc += 0;
    }
    return acc;
  }, 0);

  const handleLogin = () => {
    // Navigate to the "/forgotPsw" route
    navigate('/login');
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const redirectPay = () => {
    if (resultStats?.isSuccess) {
      window.location.href = resultStats?.data?.hostedInvoiceUrl;
    }
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={!resultStats?.isSuccess ? handleCloseDialog : undefined}
        maxWidth="xs"
        fullWidth
      >
        <Card component={Paper}>
          {!resultStats?.isSuccess && (
            <CardHeader
              action={
                <IconButton color="secondary" aria-label="close" onClick={handleCloseDialog}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              }
            />
          )}

          <CardContent>
            {resultStats5?.isLoading ? (
              <Typography variant="subtitle1" textAlign={'center'}>
                {'Loading'}
              </Typography>
            ) : resultStats5?.isError ? (
              <Typography variant="subtitle1" textAlign={'center'}>
                {resultStats5?.error?.data?.message}
                <br />
                <Link
                  color={'secondary'}
                  underline="hover"
                  component="button" // Use "button" instead of "routerLink"
                  onClick={handleLogin}
                >
                  Login
                </Link>{' '}
                at here
              </Typography>
            ) : (
              !verified && (
                <>
                  <Typography variant="subtitle1" textAlign={'center'} mb={2}>
                    Verification
                  </Typography>
                  {otpError && (
                    <Chip label={`Incorrect OTP please try again `} color="error" sx={{ mb: 2, width: '100%' }} />
                  )}

                  <Typography textAlign={'center'} mb={2}>
                    Code has sent to <br /> {publicUser?.email}
                  </Typography>
                  <Stack gap={2} flexDirection={'row'} justifyContent={'center'}>
                    {[...Array(6)].map((_, index) => (
                      <Box key={index}>
                        <TextField
                          variant="outlined"
                          type="number"
                          fullWidth
                          size="small"
                          inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                          value={otp[index] || ''}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          // eslint-disable-next-line no-return-assign
                          inputRef={(ref) => (inputRefs.current[index] = ref)}
                        />
                      </Box>
                    ))}
                  </Stack>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2 }}
                    color="primary"
                    onClick={() => onClick(false, true)}
                  >
                    Verify
                  </Button>

                  {resendCountdown === 0 ? (
                    <Typography textAlign="center" mt={2}>
                      Have not received the verification code?{' '}
                      <Typography
                        color="primary"
                        fontWeight="bold"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onClick(true, false)}
                      >
                        Resend
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography textAlign="center" mt={2} variant="body2">
                      We've sent you again now you can resend code in {resendCountdown} seconds
                    </Typography>
                  )}
                </>
              )
            )}

            {verified && (
              <>
                {verified && <Chip label={`Verified : ${publicUser?.email}`} color="success" />}
                <Typography mt={0.5}>
                  {resultStats?.isLoading ? (
                    <Typography my={2}>Loading...</Typography>
                  ) : resultStats?.isSuccess ? (
                    <>
                      <Typography variant="h6" my={2}>
                        Your {hotDesk ? 'packages' : 'purchases'} has been confirmed
                      </Typography>
                      <Typography variant="h6" mt={2}>
                        Payment Options
                      </Typography>
                      <Typography mt={1}>
                        How would you like to pay? Choose from the options below to proceed with your payment.
                      </Typography>

                      <Stack
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        mt={4}
                        gap={2}
                        flexWrap={'wrap'}
                      >
                        {stripe && (
                          <Button variant="contained" onClick={redirectPay}>
                            Pay now
                          </Button>
                        )}
                        <Button variant="contained" onClick={() => navigate('/')}>
                          Pay at space
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    resultStats?.error && Object.values(resultStats.error.data).find((error) => error.length > 0)
                  )}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Dialog>

      <BootstrapDialog
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: {
              sm: '470px',
              xs: '90%',
            },
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {hotDesk ? 'Package Summary' : 'Product Summary'}
        </DialogTitle>
        <IconButton
          color="secondary"
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Iconify icon="eva:close-fill" />
        </IconButton>
        <DialogContent dividers>
          <Stack mt={2}>
            {products.map((item, idx) => (
              <Box key={idx}>
                <Stack
                  justifyContent={'space-between'}
                  gap={2}
                  flexDirection={'row'}
                  alignItems={'flex-end'}
                  flexWrap={'wrap'}
                >
                  <Stack>
                    <Typography variant="subtitle1">{'Name'}</Typography>

                    <Typography>{item.name}</Typography>
                  </Stack>

                  <Stack>
                    <Typography variant="subtitle1">{'Qty'}</Typography>

                    <Typography>{item.quantity > 0 ? item.quantity : 1}x</Typography>
                  </Stack>

                  <Stack>
                    <Typography variant="subtitle1">{'Price'}</Typography>

                    <Typography>
                      {item.quantity !== 0
                        ? `${item.price} ${currency || currentCurrency}`
                        : `${item.price?.toFixed(2)} ${currency || currentCurrency}`}
                    </Typography>
                  </Stack>

                  {item?.inclusiveTax ? (
                    <Stack textAlign={'center'}>
                      <Typography>No Tax</Typography>
                    </Stack>
                  ) : item?.taxRateObj ? (
                    <>
                      {item?.taxRateObj?.setOverallTax ? (
                        <Stack textAlign={'center'}>
                          <Typography variant="subtitle1">Service</Typography>

                          <Typography>{item?.taxRateObj?.serviceCharges}</Typography>
                        </Stack>
                      ) : (
                        <>
                          <Stack textAlign={'center'}>
                            <Typography variant="subtitle1">Rent Fee</Typography>

                            <Typography>{item?.taxRateObj?.rentalFee} %</Typography>
                          </Stack>

                          <Stack textAlign={'center'}>
                            <Typography variant="subtitle1">Service</Typography>

                            <Typography>{item?.taxRateObj?.serviceCharges}%</Typography>
                          </Stack>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {globalTaxRateObj?.setOverallTax ||
                      (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax) ? (
                        <Stack>
                          <Typography variant="subtitle1">Service</Typography>
                          <Typography>
                            {globalTaxRateObj?.serviceCharges ||
                              (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)}
                          </Typography>
                        </Stack>
                      ) : (
                        <>
                          <Stack>
                            <Typography variant="subtitle1">Rent Fee</Typography>
                            <Typography>
                              {globalTaxRateObj?.rentalFee ||
                                (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)}{' '}
                              %
                            </Typography>
                          </Stack>
                          <Stack>
                            <Typography variant="subtitle1">Service</Typography>
                            <Typography>
                              {globalTaxRateObj?.serviceCharges ||
                                (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)}
                              %
                            </Typography>
                          </Stack>
                        </>
                      )}
                    </>
                  )}
                </Stack>

                {idx !== products?.length - 1 && <Divider sx={{ my: 1 }} />}
              </Box>
            ))}
          </Stack>

          <Box mt={2}>
            <TextField
              label="Coupon Code (optional)"
              variant="filled"
              fullWidth
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
            />

            {discount && (
              <Box mt={0.5}>
                {isLoading ? (
                  <Typography>Loading..</Typography>
                ) : isError ? (
                  <Typography sx={{ color: 'error.main' }}>{error?.data?.error || error?.data?.message}</Typography>
                ) : (
                  <>
                    {data?.validOnProducts && new Date(data.validTill) > new Date() ? (
                      <Typography />
                    ) : new Date(data.validTill) < new Date() ? (
                      <Typography sx={{ color: 'error.main' }}>Discount has expired</Typography>
                    ) : (
                      <Typography sx={{ color: 'error.main' }}>
                        This coupon does not offer a discount for this product
                      </Typography>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>

          <Stack alignItems={'flex-end'} mt={2}>
            <Stack columnGap={1}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography marginRight={2}>Subtotal:</Typography>

                <Typography fontWeight={'600'}>
                  {totalPrice.toFixed(2)} {currency || currentCurrency}
                </Typography>
              </Stack>

              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography marginRight={2}>Total Tax:</Typography>

                <Typography fontWeight={'600'}>
                  {totalTax.toFixed(2)} {currency || currentCurrency}
                </Typography>
              </Stack>

              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography marginRight={2}>Discount:</Typography>

                {isSuccess && data?.validOnProducts && new Date(data.validTill) > new Date() ? (
                  <Typography fontWeight={'600'}>
                    {data?.fixedAmount ? `${data?.amount} ${currency || currentCurrency}` : `${data?.amount} %`}
                  </Typography>
                ) : (
                  <Typography fontWeight={'600'}>0</Typography>
                )}
              </Stack>

              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography marginRight={2}>Total:</Typography>

                <Typography fontWeight={'600'}>
                  {isSuccess && data?.validOnProducts && new Date(data.validTill) > new Date()
                    ? data?.fixedAmount
                      ? `${Math.max((data?.amount > totalPrice ? 0 : totalPrice - data?.amount) + totalTax, 0).toFixed(
                          2
                        )} ${currency || currentCurrency}`
                      : `${Math.max(totalPrice - (totalPrice * data?.amount) / 100 + totalTax, 0).toFixed(2)} ${
                          currency || currentCurrency
                        }`
                    : `${(totalPrice + totalTax).toFixed(2)} ${currency || currentCurrency}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={onClick} variant="contained" fullWidth>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
              {hotDesk ? 'Confirm Packages' : 'Confirm Products'}
            </Stack>
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>

      <CustomAlert
        type={type ? 'success' : 'error'}
        message={message}
        open={openAlert}
        autoHideDuration={2000}
        setOpen={setOpenAlert}
      />
    </>
  );
};

export default ProductSummary;
