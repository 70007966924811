import { Box, Divider, Drawer, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import Iconify from '../iconify';
import { useCheckHotDeskAvailibilityMutation, usePurchaseProductMutation } from '../../Slices/spaceApi';
import Scrollbar from '../scrollbar';
import EnquiryProductForm from './EnquiryProductForm';

const EnquiryProduct = ({
  hotDesk,
  openFilter,
  onCloseFilter,
  product,
  setCheckoutProduct,
  checkoutProduct,
  publicRoom,
  hotDeskDate,
  weekdaysData,
  duration,
  categories,
}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    if (publicRoom) {
      setCheckoutProduct((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        products: [
          ...prevCheckoutProduct.products,
          {
            product: product.id,
            quantity: +data?.[`quantity_${product.id}`] || 1,
            name: product.name,
            price: product.price,
            taxRateObj: product.taxRateObj,
            inclusiveTax: product.inclusiveTax,
            startDate: +data?.[`startDate_${product.id}`],
          },
        ],
        publicUser: {
          name: data?.name,
          email: data?.email,
          phone_number: data?.phone_number,
        },
      }));
    } else {
      setCheckoutProduct((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        products: [
          ...prevCheckoutProduct.products,
          {
            product: product.id,
            quantity: +data?.[`quantity_${product.id}`] || 1,
            name: product.name,
            price: product.price,
            taxRateObj: product.taxRateObj,
            inclusiveTax: product.inclusiveTax,
            startDate: +data?.startDate,
          },
        ],
        publicUser: {
          name: data?.hotDesk,
          email: data?.hotDesk,
          phone_number: data?.hotDesk,
        },
      }));
    }

    onCloseFilter();
  };

  const [checkAvailability, resultStats] = useCheckHotDeskAvailibilityMutation();
  console.log(resultStats?.isError);

  useEffect(() => {
    if (watch('hotDesk')) {
      const startOfDay = watch('startDate')?.startOf('day');
      const endOfDay = watch('startDate')?.endOf('day');

      checkAvailability({
        spaceId: spaceId || currentSpaceId,
        data: {
          startTime: startOfDay.add(1, 'minute').unix(), // 12:01 am
          endTime: endOfDay.subtract(1, 'minute').unix(), // 11:59 pm
          meetingRoom: null,
        },
      });
    }
  }, [watch('hotDesk'), watch('startDate')]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: {
            width: { xs: '98%', sm: '375px' },
            border: 'none',
            overflow: 'hidden',
            zIndex: hotDesk ? 2 : 'default',
          },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Add to basket ({product?.name})
          </Typography>
          <IconButton onClick={onCloseFilter} color="secondary">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />

        <Scrollbar>
          <Stack spacing={2} sx={{ p: 1 }}>
            <EnquiryProductForm
              errors={errors}
              register={register}
              trigger={trigger}
              watch={watch}
              control={control}
              setValue={setValue}
              product={product}
              publicRoom={publicRoom}
              hotDesk={hotDesk}
              hotDeskDate={hotDeskDate}
              weekdaysData={weekdaysData}
              duration={duration}
              products={checkoutProduct}
              categories={categories}
            />
          </Stack>
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown }}>
          <Box sx={{ px: 3, my: 2 }}>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              disabled={!product.oneOffPurchase || resultStats?.isError}
              variant="contained"
              fullWidth
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                {resultStats?.isError
                  ? 'Hot Desk Not Available on this date'
                  : product.oneOffPurchase
                  ? 'Add to basket'
                  : 'Contact with admin'}
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default EnquiryProduct;
