import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

const createRequest = (url) => ({ url });

const createPostRequest = (url, data) => ({
  method: 'POST',
  url,
  body: data,
});

export const constantApis = createApi({
  reducerPath: 'constantApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getTourLocations: builder.query({
      query: (companyName) => createRequest(`/tour-locations/${companyName}/`),
    }),
    createTour: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/tour/`, data),
    }),
    createPublicMeetingRoomBooking: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/v2/booking/public/meetingRoom/`, data),
    }),
    getPublicMeetingRooms: builder.query({
      query: (spaceId) => createRequest(`/meeting-rooms/${spaceId}/public`),
    }),
    createPublicHotDeskBooking: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/v2/booking/public/hotDesk/`, data),
    }),
    getTourQuestions: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/tourQuestion/`),
    }),
    getBrands: builder.query({
      query: (companyName) => createRequest(`/fetch-brand/${companyName}/`),
    }),
    sendOtp: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/profile/sendOtp/`, data),
    }),
    getProductCategory: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/productCategory/`),
    }),
    getProductBasedCategory: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/product/?dayPass=True`),
    }),
  }),
});

export const {
  useGetTourLocationsQuery,
  useCreateTourMutation,
  useCreatePublicMeetingRoomBookingMutation,
  useGetPublicMeetingRoomsQuery,
  useCreatePublicHotDeskBookingMutation,
  useGetTourQuestionsQuery,
  useGetBrandsQuery,
  useSendOtpMutation,
  useGetProductCategoryQuery,
  useGetProductBasedCategoryQuery,
} = constantApis;
