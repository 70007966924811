import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Card, Grid, Typography, TextField, Button, Stack, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import { ConfirmComponent, CustomAlert } from '../utils/Alert';
import { useGetPackagesQuery, useRegisterVisitorMutation } from '../Slices/spaceApi';
import VisitorForm from '../components/Visitors/VisitorForm';

function BookingEnquiryPage() {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    trigger,
    setValue,
    control,
  } = useForm();

  const navigate = useNavigate();
  const [registerVisitor, resultStats] = useRegisterVisitorMutation();

  const [loading, setLoading] = useState(false);

  const [typeRegister, setTypeRegister] = useState('');
  const [messageRegister, setMessageRegister] = useState('');
  const [open, setOpen] = useState(false);

  const [dailog, setDailog] = useState(false);
  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  useEffect(() => {
    if (!checkOldUser) {
      setDailog(true);
    } else {
      setDailog(false);
    }
  }, [checkOldUser]);

  const onSubmit = (data) => {
    const convertFormData = (formData) => ({
      name: formData.name,
      companyName: formData.companyName,
      email: formData.email,
      phone_number: formData.phone_number,
      visit: {
        expected_visit_datetime: formData.expected_visit_datetime.format('YYYY-MM-DDTHH:mm'),
        purpose_of_visit: formData.purpose_of_visit,
        location: formData.location,
        person_visiting: formData.person_visiting,
      },
    });

    const convertedData = convertFormData(data);
    registerVisitor({ spaceId: spaceId || currentSpaceId, data: convertedData });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setTypeRegister(true);
      setLoading(false);
      setOpen(true);
      setTimeout(() => {
        navigate('/visitors');
      }, 1000);
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageRegister(errorMessage);
      setLoading(false);
      setOpen(true);
      setTypeRegister(false);
    }
  }, [resultStats]);

  if (pacakgeLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Add Visitor | {name || currentName}</title>
      </Helmet>

      <ConfirmComponent openCheck={dailog} />

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Register Visitor
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <VisitorForm
            errors={errors}
            loading={loading}
            register={register}
            control={control}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
          />
        </form>
      </Container>

      <CustomAlert
        type={typeRegister ? 'success' : 'error'}
        message={messageRegister || 'Visitor added successfully.'}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default BookingEnquiryPage;
