import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from '@emotion/styled';
import { formatDateNotTime } from '../utils/displayDateTime';
import { useGetMeetingRoomsQuery, useGetPackagesQuery, useViewHotDeskQuery } from '../Slices/spaceApi';
import EnquiryCheck from '../components/Booking/EnquiryCheck';
import Iconify from '../components/iconify';
import Loader from '../components/Loader/Loader';

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  '& .MuiTabs-indicatorSpan': {},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  minWidth: 'auto',
  '&.Mui-focusVisible': {},
}));

function BookingEnquiryPage() {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [week, setWeek] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { spaceId, currentSpaceId, currentCurrency, currency, billingOption, currentBillingOption } = useSelector(
    (item) => item.spaceReducer
  );

  const { data: hotDesksAvailablity, isLoading: hotdeskLoading } = useViewHotDeskQuery({
    spaceId: spaceId || currentSpaceId,
    week,
  });

  const currentDate = new Date();
  const offsetInDays = (week - 1) * 7; // Calculate the offset in days based on the week
  currentDate.setDate(currentDate.getDate() + offsetInDays); // Set the currentDate based on the offset

  function formatDate(date) {
    // eslint-disable-next-line no-restricted-globals
    if (!(date instanceof Date && !isNaN(date))) {
      return 'Invalid Date';
    }

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }

  const [selectBookingEnquiry, setselectBookingEnquiry] = useState();

  const handleType = ({ hotDeskDate }) => {
    setType({ title: 'Book Hotdesk', isButton: true, value: 2 });
    setOpenFilter(true);
    setselectBookingEnquiry({ room: { title: 'Hot Desk', capacity: 1 }, id: Date.now(), hotDeskDate });
  };

  const handleEventClick = (clickInfo) => {
    if (dayjs(resultCount?.maxDate).unix() >= dayjs(clickInfo.event.startStr).unix()) {
      if (clickInfo.event.extendedProps.available) {
        handleType({ hotDeskDate: clickInfo.event.startStr });
      }
    }
  };

  const location = useLocation();

  // const { data: spaceTypesData, isLoading: spaceLoading } = useGetSpaceTypesQuery();

  const [type, setType] = useState({ title: 'Meeting Rooms', value: 1 });

  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const { data, isLoading } = useGetMeetingRoomsQuery({ spaceId: spaceId || currentSpaceId });

  // const { data: getSpaceTypes, isLoading: spaceTypesLoading } = useGetSpaceTypesQuery();

  const [locations, setLocations] = useState([]);
  const [spaceTypes, setSpaceTypes] = useState();
  const [selectedSpaceType, setSelectedSpaceType] = useState();
  const [selectedSpace, setSelectedSpace] = useState();
  const [meetRoom, setMeetRoom] = useState('');

  useEffect(() => {
    if (data?.length > 0) {
      const uniqueSpaces = Array.from(new Set(data.map((item) => item.space.id))).map((id) => {
        const spaceData = data.find((item) => item.space.id === id);
        return { id: spaceData.space.id, name: spaceData.space.name };
      });

      setLocations(uniqueSpaces);
      setSelectedSpace(uniqueSpaces?.[0]);
    }
  }, [data]);

  useEffect(() => {
    if (data?.length > 0) {
      const filteredData = data.filter((item) => item?.spaceType !== null && item?.spaceType !== 19);

      const uniqueSpaces = Array.from(new Set(filteredData.map((item) => item.spaceTypeObj?.id))).map((id) => {
        const spaceData = filteredData.find((item) => item.spaceTypeObj?.id === id);

        return { id: spaceData.spaceTypeObj?.id, name: spaceData.spaceTypeObj?.name };
      });

      setSpaceTypes(uniqueSpaces);

      setSelectedSpaceType(uniqueSpaces?.[0]);
    }
  }, [data]);

  useEffect(() => {
    // Parse the tabValue from the query parameters and set the initial value accordingly
    const searchParams = new URLSearchParams(location.search);
    // eslint-disable-next-line camelcase
    const created_at = searchParams.get('created_at');
    setMeetRoom(created_at);
  }, [location.search]);

  const handleEnquiry = (item) => {
    setOpenFilter(true);
    setselectBookingEnquiry({ room: item, id: item.id }); // Include a unique identifier
  };

  const [openFilter, setOpenFilter] = useState(false);

  const handleCloseFilter = () => {
    setType({ title: 'Meeting Rooms', value: 1 });
    setOpenFilter(false);
  };

  const [filteredData, setFilteredData] = useState([]);

  const dateRanges = [];
  const countRanges = [];

  packages?.assignedPackages?.forEach((obj) => {
    // eslint-disable-next-line camelcase
    const { status, renew_date, remaining_hours, purchase } = obj;

    // Check if the status is 1
    if (status === 1) {
      if (purchase && purchase?.dayPassCount >= 1) {
        const purchaseData = {
          minDate: dayjs(),
          maxDate: dayjs(renew_date).subtract(1, 'day'),
          dayPass: purchase?.dayPassCount,
        };
        countRanges.push(purchaseData);
      }
      // Loop through each remaining_hours item
      // eslint-disable-next-line camelcase
      remaining_hours.forEach((hours) => {
        // Loop through each meetingRoom
        hours.meetingRoom.forEach((room) => {
          const roomId = room.id;
          const roomData = {
            minDate: dayjs(),
            maxDate: dayjs(renew_date).subtract(1, 'day'),
            room_id: roomId,
            hours: hours?.hours,
          };
          dateRanges.push(roomData);
        });
      });
    }
  });

  console.log(countRanges, 'countRanges');

  // console.log(packages?.assignedPackages?.filter((item) => item?.status === 1 && item?.purchase));

  const filteredDateRanges = {};

  dateRanges.forEach((roomData) => {
    // eslint-disable-next-line camelcase
    const { minDate, maxDate, room_id, hours } = roomData;

    // eslint-disable-next-line camelcase
    if (!filteredDateRanges[room_id] || maxDate > filteredDateRanges[room_id].maxDate) {
      // eslint-disable-next-line camelcase
      filteredDateRanges[room_id] = { minDate, maxDate, room_id, hours };
    }
  });

  function processArray(arr) {
    if (arr?.length === 0) {
      return null; // Return null if the array is empty
    }

    let { minDate } = arr[0];
    let { maxDate } = arr[0];
    let dayPassTotal = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].dayPass <= 0) {
        // eslint-disable-next-line no-continue
        continue; // Skip objects with dayPass <= 0
      }

      // Update minDate if necessary
      if (arr[i].minDate < minDate) {
        minDate = arr[i].minDate;
      }
      // Update maxDate if necessary
      if (arr[i].maxDate !== null && (maxDate === null || arr[i].maxDate > maxDate)) {
        maxDate = arr[i].maxDate;
      }
      // Add to dayPassTotal
      dayPassTotal += arr[i].dayPass;
    }

    // Return the result as an object
    return {
      minDate,
      maxDate,
      dayPassTotal,
    };
  }

  const resultCount = processArray(countRanges);

  // console.log(, 'resultCount');

  const resultArray = Object.values(filteredDateRanges);

  useEffect(() => {
    if (selectedSpace || selectedSpaceType) {
      const filteredRooms = data?.filter((item) => {
        const spaceCondition = !selectedSpace || item.space.id === selectedSpace.id;
        const spaceTypeCondition = !selectedSpaceType || item?.spaceTypeObj?.id === selectedSpaceType?.id;

        return spaceCondition && spaceTypeCondition;
      });

      setFilteredData(filteredRooms);
    } else {
      setFilteredData(data);
    }
  }, [data, selectedSpace, selectedSpaceType]);

  const totalRemainingDayPasses = packages?.assignedPackages
    .filter((item) => item.purchase && item.status === 1) // Ensure purchase exists and has status 1
    .reduce((total, item) => total + item.purchase.dayPassCount, 0); // Sum up dayPassCount

  const nextWeek = () => {
    setWeek(week + 1);
  };

  const prevWeek = () => {
    setWeek(week - 1);
  };

  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 7);

  const events = Array.from({ length: 7 }, (_, i) => {
    const date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + +i);

    const availabilityObj = hotDesksAvailablity?.find((item) => item.date === formatDate(date));
    const title = availabilityObj
      ? availabilityObj.available && dayjs(resultCount?.maxDate).unix() >= dayjs(date).unix()
        ? 'Want to book?'
        : !availabilityObj.available && dayjs(resultCount?.maxDate).unix() >= dayjs(date).unix()
        ? 'Not Available'
        : `No Pass for ${formatDateNotTime(dayjs(date).format(''))}`
      : 'Not Available';
    return {
      title,
      start: date,
      // allDay: true,
      available: availabilityObj?.available || false,
      count: availabilityObj?.count || 1,
    };
  });

  if (isLoading || pacakgeLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Booking </title>
      </Helmet>
      <Container>
        <Box />

        <StyledTabs
          value={value}
          onChange={handleChange}
          sx={{
            mb: 5,
            '& .MuiTabs-indicator': {
              backgroundColor: (theme) => theme.palette.text.primary,
            },
            '&.Mui-focusVisible': {
              backgroundColor: '',
              outline: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary, // Active tab text color
                backgroundColor: 'transparent',

                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="iconoir:house-rooms" sx={{ marginRight: '10px' }} />
                Resources
              </Typography>
            }
          />

          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary,
                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            // disabled={totalRemainingDayPasses === 0}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="mingcute:desk-line" sx={{ marginRight: '10px' }} />
                Hot Desks
              </Typography>
            }
          />
        </StyledTabs>

        {!meetRoom ? (
          <>
            {value === 0 ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {locations?.length > 0 && (
                      <Autocomplete
                        options={locations}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={selectedSpace}
                        onChange={(event, newValue) => {
                          setSelectedSpace(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Space" />}
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {spaceTypes?.length > 0 && (
                      <Autocomplete
                        options={spaceTypes}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={selectedSpaceType}
                        onChange={(event, newValue) => {
                          setSelectedSpaceType(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Space Type" />}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={5}>
                  <Grid item xs={12}>
                    <Typography variant="h6" paragraph>
                      Resources
                    </Typography>
                  </Grid>
                  {filteredData?.length > 0 ? (
                    filteredData?.map((item, idx) => (
                      <Grid item md={4} xs={12} key={idx}>
                        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                          <CardMedia component="img" alt="" height="140" image={item.imageUrls} />
                          <CardContent style={{ flexGrow: 1 }}>
                            <Box>
                              <Typography variant="h6" mb={2}>
                                {item.title} (Capacity - {item.capacity})
                              </Typography>
                              {/* <Typography variant="p" mb={1} component={'div'}>
                                {item.title}
                              </Typography> */}

                              <Typography variant="subtitle2" mb={1}>
                                {!resultArray?.find((room) => room.room_id === parseInt(item.id, 10)) ? (
                                  <Typography variant="subtitle2">
                                    {`Required ${parseInt(item?.price, 10).toFixed(2)}  ${currency || currentCurrency}`}
                                  </Typography>
                                ) : +billingOption === 1 || +currentBillingOption === 1 ? (
                                  `Remaining hours included package ${
                                    resultArray.find((packages) => packages.room_id === item.id)?.hours
                                  } till ${formatDateNotTime(
                                    dayjs(resultArray.find((packages) => packages.room_id === item.id)?.maxDate).format(
                                      'YYYY-MM-DDTHH:mm:ss.SSSZ'
                                    )
                                  )}`
                                ) : (
                                  `Remaining credits included package ${
                                    resultArray.find((packages) => packages.room_id === item.id)?.hours
                                  } till ${formatDateNotTime(
                                    dayjs(resultArray.find((packages) => packages.room_id === item.id)?.maxDate).format(
                                      'YYYY-MM-DDTHH:mm:ss.SSSZ'
                                    )
                                  )}`
                                )}
                              </Typography>
                              <Button variant="contained" fullWidth onClick={() => handleEnquiry(item)}>
                                Resource Enquiry
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12} textAlign={'center'}>
                      <Typography variant="h6" paragraph>
                        There's no Meeting Room with that filter
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" paragraph>
                    Hot Desks
                  </Typography>
                </Grid>
                {totalRemainingDayPasses > 0 ? (
                  <>
                    <Grid item xs={12}>
                      <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGrid"
                        headerToolbar={false}
                        height={isMediumScreen ? 170 : 220} // Set height to 100% for responsiveness
                        events={events}
                        initialDate={currentDate}
                        visibleRange={{ start: currentDate, end: endDate }}
                        finalDate={endDate}
                        eventClick={handleEventClick}
                        dayHeaderContent={(arg) => (
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {arg.date.toLocaleDateString('en-US', { weekday: 'short' })}
                          </div>
                        )}
                        eventContent={(eventInfo) => (
                          <Chip
                            label={eventInfo.event.title}
                            color={
                              eventInfo.event.extendedProps.available &&
                              dayjs(resultCount?.maxDate).unix() >= dayjs(eventInfo.event.startStr).unix()
                                ? 'success'
                                : 'error'
                            }
                            sx={{
                              width: '100%',
                              cursor:
                                eventInfo.event.extendedProps.available &&
                                dayjs(resultCount?.maxDate).unix() >= dayjs(eventInfo.event.startStr).unix()
                                  ? 'pointer'
                                  : 'default',
                            }}
                          />
                        )}
                        dayCellContent={(arg) => {
                          const availabilityObj = hotDesksAvailablity?.find(
                            (item) => item.date === formatDate(arg.date)
                          );
                          const isAvailable = availabilityObj ? availabilityObj.available : false;
                          const count = availabilityObj?.count;

                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                mt: 2,
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Box>{arg.date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</Box>
                              <Typography color="primary">{`${count || ''} ${
                                count === 1 ? 'seat' : count === 0 ? 'no seat' : 'seats'
                              }`}</Typography>
                            </Box>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Button onClick={prevWeek} variant="contained" disabled={week === 1}>
                          Prev Week
                        </Button>

                        <Button onClick={nextWeek} variant="contained">
                          Next Week
                        </Button>
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" paragraph>
                      You don't have any available day pass yet please purchase{' '}
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.action.tertiaryColor,
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                        onClick={() => navigate('/products?hotDesk=true')}
                      >
                        package
                      </Typography>{' '}
                      to get day passes.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        ) : (
          <Stack justifyContent={'center'}>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 1 }}>
              Thank You for reserving in our space
            </Typography>

            <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
              We are waiting for you at&nbsp;
              {formatDate(dayjs.unix(meetRoom).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))}
            </Typography>
          </Stack>
        )}

        {selectBookingEnquiry && (
          <EnquiryCheck
            openFilter={openFilter}
            onCloseFilter={handleCloseFilter}
            {...selectBookingEnquiry}
            type={type?.value}
            publicRoom={false}
          />
        )}
      </Container>
    </>
  );
}

export default BookingEnquiryPage;
