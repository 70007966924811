import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_API_URL;

const createRequest = (url) => ({
  method: 'GET',
  url,
});

const createPostRequest = (url, data) => ({
  method: 'POST',
  url,
  body: data,
});

const createDeleteRequest = (url, data) => ({
  method: 'DELETE',
  url,
  body: data,
});

const createUpdateRequest = (url, data) => ({
  method: 'PUT',
  url,
  body: data,
});

export const spaceApi = createApi({
  reducerPath: 'apisSpace',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const states = getState();
      headers.set('credentials', states.spaceReducer.credentials || states.spaceReducer.currentCredentials);
    },
  }),
  tagTypes: [
    'user',
    'visitors',
    'visits',
    'tickets',
    'posts',
    'booking',
    'notification',
    'package',
    'purchases',
    'companyUsers',
    'invoices',
    'hotDeskAvalibility',
  ],
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: (userId) => createRequest(`/profile/${userId}`),
      providesTags: ['user'],
    }),
    getSpecficUserDetails: builder.query({
      query: ({ userId, spaceId }) => createRequest(`space/${spaceId}/profile/${userId}/`),
    }),
    updateUserDetails: builder.mutation({
      query: ({ userId, data }) => createUpdateRequest(`/profile/${userId}/`, data),
      invalidatesTags: ['user'],
    }),
    getVisitors: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/visitors/`),
      providesTags: ['visitors'],
    }),
    deleteVisitor: builder.mutation({
      query: ({ spaceId, visitorId }) => createDeleteRequest(`/space/${spaceId}/visitors/${visitorId}/`),
      invalidatesTags: ['visitors', 'visits'],
    }),
    getVisits: builder.query({
      query: ({ spaceId, type }) => {
        let url = `/space/${spaceId}/visits/`;

        if (type === 1) {
          url += `?past=False`;
        } else {
          url += `?past=True`;
        }

        return createRequest(url);
      },
      providesTags: ['visits'],
    }),
    deleteVisit: builder.mutation({
      query: ({ spaceId, visitId }) => createDeleteRequest(`/space/${spaceId}/visits/${visitId}/`),
      invalidatesTags: ['visits'],
    }),
    registerVisitor: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/visitors/`, data),
      invalidatesTags: ['visitors', 'visits'],
    }),
    addVisit: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/visits/`, data),
      invalidatesTags: ['visits'],
    }),
    updateVisit: builder.mutation({
      query: ({ spaceId, visitId, data }) => createUpdateRequest(`/space/${spaceId}/visits/${visitId}/`, data),
      invalidatesTags: ['visits'],
    }),
    updateVisitor: builder.mutation({
      query: ({ spaceId, visitorId, data }) => createUpdateRequest(`/space/${spaceId}/visitors/${visitorId}/`, data),
      invalidatesTags: ['visitors', 'visits'],
    }),
    getCategories: builder.query({
      query: () => createRequest(`/supportCategories/`),
    }),
    getTickets: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId, status, activePage, page_size }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/supportTicket?open=True&page_size=${page_size}&page=${activePage}`;
        if (status) {
          url += `&status=${status}`;
        }

        return createRequest(url);
      },
      providesTags: ['tickets'],
    }),
    getClosedTickets: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId, status, activePage, page_size }) =>
        // eslint-disable-next-line camelcase
        createRequest(`/space/${spaceId}/supportTicket?status=${status}&page_size=${page_size}&page=${activePage}`),
      providesTags: ['tickets'],
    }),
    getSpecficTicket: builder.query({
      query: ({ spaceId, ticketId }) => createRequest(`/space/${spaceId}/supportTicket/${ticketId}/`),
      providesTags: ['tickets'],
    }),
    deleteTicket: builder.mutation({
      query: ({ spaceId, ticketId }) => createDeleteRequest(`/space/${spaceId}/supportTicket/${ticketId}/`),
      invalidatesTags: ['tickets'],
    }),
    updateTicket: builder.mutation({
      query: ({ spaceId, ticketId, data }) => createUpdateRequest(`/space/${spaceId}/supportTicket/${ticketId}/`, data),
      invalidatesTags: ['tickets'],
    }),
    createTicket: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/supportTicket/`, data),
      invalidatesTags: ['tickets'],
    }),
    roleList: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/role/`),
    }),
    getCommunities: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/spaceowner/`),
    }),
    getPartners: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/partner/`),
    }),
    getOffer: builder.query({
      query: ({ spaceId, partnerId }) => {
        const queryParams = partnerId ? `?partner=${partnerId}` : '';
        return createRequest(`/space/${spaceId}/offer/${queryParams}`);
      },
    }),
    getPosts: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/post/`),
      providesTags: ['posts'],
    }),
    createPost: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/post/`, data),
      invalidatesTags: ['posts'],
    }),
    deletePost: builder.mutation({
      query: ({ spaceId, postId }) => createDeleteRequest(`/space/${spaceId}/post/${postId}/`),
      invalidatesTags: ['posts'],
    }),
    updatePost: builder.mutation({
      query: ({ spaceId, postId, data }) => createUpdateRequest(`/space/${spaceId}/post/${postId}/`, data),
      invalidatesTags: ['posts'],
    }),
    createComment: builder.mutation({
      query: ({ spaceId, postId, data }) => createPostRequest(`/space/${spaceId}/post/${postId}/comment/`, data),
      invalidatesTags: ['posts'],
    }),
    deleteComment: builder.mutation({
      query: ({ spaceId, postId, commentId }) =>
        createDeleteRequest(`/space/${spaceId}/post/${postId}/comment/${commentId}/`),
      invalidatesTags: ['posts'],
    }),
    updateComment: builder.mutation({
      query: ({ spaceId, postId, data, commentId }) =>
        createUpdateRequest(`/space/${spaceId}/post/${postId}/comment/${commentId}/`, data),
      invalidatesTags: ['posts'],
    }),
    getBlocksUsers: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/block/`),
    }),
    getPackages: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/assignedPackage/`),
      providesTags: ['package'],
    }),
    getSinglePackage: builder.query({
      query: ({ spaceId, packageId }) => createRequest(`/space/${spaceId}/assignedPackage/${packageId}`),
      providesTags: ['package'],
    }),
    getInvoices: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId, status, activePage, page_size }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/v2/invoice/?page=${activePage}&page_size=${page_size}`;
        if (parseInt(status, 10) === 0) {
          url += `&paid=${0}`;
        }
        if (parseInt(status, 10) === 1) {
          url += `&paid=${1}`;
        }

        return createRequest(url);
      },
      providesTags: ['invoices'],
    }),
    getInvoicesGlobal: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId }) => {
        // eslint-disable-next-line camelcase
        const url = `/space/${spaceId}/v2/invoice/`;

        return createRequest(url);
      },
    }),
    getInvoice: builder.query({
      query: ({ spaceId, invoiceId }) => createRequest(`/space/${spaceId}/v2/invoice/${invoiceId}`),
    }),
    getMeetingRooms: builder.query({
      query: ({ spaceId }) => createRequest(`/meeting-rooms/${spaceId}/related?HotDesk=False`),
    }),
    getSlots: builder.mutation({
      query: ({ data, spaceId }) => createPostRequest(`/meeting-rooms/${spaceId}/slots`, data),
    }),
    createBooking: builder.mutation({
      query: ({ data, spaceId }) => createPostRequest(`/space/${spaceId}/v2/booking/`, data),
      invalidatesTags: ['booking', 'notification', 'package'],
    }),
    hotDesksBooking: builder.mutation({
      query: ({ data, spaceId }) => createPostRequest(`/space/${spaceId}/v2/booking/hotDesk/`, data),
      invalidatesTags: ['booking', 'notification', 'package', 'hotDeskAvalibility'],
    }),
    getBookings: builder.query({
      query: ({ spaceId, type }) => {
        const typeQueryParam = type ? `&type=${type}` : '';
        return createRequest(`/space/${spaceId}/v2/booking/?cancel=False&past=${'False'}${typeQueryParam}`);
      },
      providesTags: ['booking'],
    }),
    deleteBooking: builder.mutation({
      query: ({ bookingId, spaceId }) => createDeleteRequest(`/space/${spaceId}/v2/booking/${bookingId}/`),
      invalidatesTags: ['booking', 'package'],
    }),
    updateBooking: builder.mutation({
      query: ({ bookingId, data, spaceId }) => createUpdateRequest(`/space/${spaceId}/v2/booking/${bookingId}/`, data),
      invalidatesTags: ['booking', 'package'],
    }),
    getPastBookings: builder.query({
      query: ({ spaceId, type }) =>
        // eslint-disable-next-line no-undef
        createRequest(`/space/${spaceId}/v2/booking/?past=${'True'}&type=${type}`),
      providesTags: ['booking'],
    }),
    getNotifications: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/notifications/`),
      providesTags: ['notification'],
    }),
    deleteNotification: builder.mutation({
      query: ({ spaceId, notificationId }) => createDeleteRequest(`/space/${spaceId}/notifications/${notificationId}/`),
      invalidatesTags: ['notification'],
    }),
    updateNotification: builder.mutation({
      query: ({ spaceId, notificationId, data }) =>
        createUpdateRequest(`/space/${spaceId}/notifications/${notificationId}/`, data),
      invalidatesTags: ['notification'],
    }),
    getInterests: builder.query({
      query: () => createRequest(`/interests-list/`),
    }),
    getSkills: builder.query({
      query: () => createRequest(`/skills-list/`),
    }),
    getAllProfiles: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId, page, page_size, name, skills, interests, companyIdForEmployee }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/profile/?page=${page}&page_size=${page_size}`;

        if (name) {
          url += `&name=${name}`;
        }

        if (skills) {
          const skillsParam = skills; // Convert skills array to a comma-separated string
          url += `&skill=${skillsParam}`;
        }

        if (interests) {
          const interestParam = interests; // Convert skills array to a comma-separated string
          url += `&interest=${interestParam}`;
        }

        if (companyIdForEmployee) {
          url += `&companyIdForEmployee=${companyIdForEmployee}`;
        }

        return createRequest(url);
      },
    }),
    getAllTicketReplies: builder.query({
      query: ({ spaceId, ticketId }) => createRequest(`/space/${spaceId}/ticket/${ticketId}/ticketMessage/`),
      providesTags: ['ticketReplies'],
    }),
    createTicketReply: builder.mutation({
      query: ({ spaceId, ticketId, data }) =>
        createPostRequest(`/space/${spaceId}/ticket/${ticketId}/ticketMessage/`, data),
      invalidatesTags: ['ticketReplies'],
    }),
    deleteTikcetReply: builder.mutation({
      query: ({ spaceId, ticketId, replyId }) =>
        createDeleteRequest(`/space/${spaceId}/ticket/${ticketId}/ticketMessage/${replyId}/`),
      invalidatesTags: ['ticketReplies'],
    }),
    updateTikcetReply: builder.mutation({
      query: ({ spaceId, ticketId, replyId, data }) =>
        createUpdateRequest(`/space/${spaceId}/ticket/${ticketId}/ticketMessage/${replyId}/`, data),
      invalidatesTags: ['ticketReplies'],
    }),
    customProfile: builder.query({
      query: ({ spaceId, profileId }) => createRequest(`/space/${spaceId}/profile/${profileId}/`),
    }),
    getProducts: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId, activePage, page_size, categoryType }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/product/?page=${activePage}&page_size=${page_size}`;
        if (categoryType === 'hotDesk') {
          url += `&dayPass=True`;
        } else if (categoryType) {
          url += `&category=${categoryType}`;
        }

        return createRequest(url);
      },
    }),
    purchaseProduct: builder.mutation({
      query: ({ data, spaceId }) => createPostRequest(`/space/${spaceId}/purchase/`, data),
      invalidatesTags: ['purchases', 'package', 'invoices'],
    }),
    getSpaceTypes: builder.query({
      query: () => createRequest(`/spaceTypes/`),
    }),
    getPurchases: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/purchase/`),
      providesTags: ['purchases'],
    }),
    getDiscounts: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId, code }) => createRequest(`/space/${spaceId}/discountCode/getDiscount/${code}/`),
    }),
    getCompaniesUser: builder.query({
      // eslint-disable-next-line camelcase
      query: ({ spaceId, companyId, page_size, activePage, status }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/profile/?companyId=${companyId}&page=${activePage}&page_size=${page_size}`;

        if (status) {
          url += `&name=${status}`;
        }
        return createRequest(url);
      },
      providesTags: ['companyUsers'],
    }),
    deleteUser: builder.mutation({
      query: ({ spaceId, userId }) => createDeleteRequest(`/space/${spaceId}/profile/${userId}/`),
      invalidatesTags: ['companyUsers'],
    }),
    updateUser: builder.mutation({
      query: ({ spaceId, userId, data }) => createUpdateRequest(`/space/${spaceId}/profile/${userId}/`, data),
      invalidatesTags: ['companyUsers'],
    }),
    createUser: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/profile/`, data),
      invalidatesTags: ['companyUsers'],
    }),
    useGetDuration: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/customSpaceDuration/`),
    }),
    getInvoicePayUrl: builder.query({
      query: ({ spaceId, invoiceId }) => createRequest(`/space/${spaceId}/v2/invoice/${invoiceId}/stripeUrl/`),
    }),
    checkHotDeskAvailibility: builder.mutation({
      query: ({ spaceId, data }) => createPostRequest(`/space/${spaceId}/v2/booking/checkHotDeskAvailaibility/`, data),
    }),
    viewHotDesk: builder.query({
      query: ({ spaceId, week }) =>
        createRequest(`/space/${spaceId}/v2/booking/viewHotDeskAvailaibility/?week=${week}`),
      providesTags: ['hotDeskAvalibility'],
    }),
  }),
});

export const {
  useGetSinglePackageQuery,
  useGetInvoicePayUrlQuery,
  useGetUserDetailsQuery,
  useUseGetDurationQuery,
  useUpdateUserDetailsMutation,
  useGetVisitorsQuery,
  useDeleteVisitorMutation,
  useDeleteVisitMutation,
  useGetVisitsQuery,
  useRegisterVisitorMutation,
  useAddVisitMutation,
  useUpdateVisitMutation,
  useUpdateVisitorMutation,
  useGetCategoriesQuery,
  useGetTicketsQuery,
  useDeleteTicketMutation,
  useUpdateTicketMutation,
  useCreateTicketMutation,
  useRoleListQuery,
  useGetCommunitiesQuery,
  useGetPartnersQuery,
  useGetOfferQuery,
  useGetPostsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
  useCreatePostMutation,
  useDeletePostMutation,
  useUpdatePostMutation,
  useGetBlocksUsersQuery,
  useGetPackagesQuery,
  useGetInvoicesQuery,
  useGetMeetingRoomsQuery,
  useCreateBookingMutation,
  useGetBookingsQuery,
  useDeleteBookingMutation,
  useUpdateBookingMutation,
  useGetPastBookingsQuery,
  useGetNotificationsQuery,
  useGetSlotsMutation,
  useHotDesksBookingMutation,
  useGetInterestsQuery,
  useGetSkillsQuery,
  useGetAllProfilesQuery,
  useGetAllTicketRepliesQuery,
  useCreateTicketReplyMutation,
  useDeleteTikcetReplyMutation,
  useUpdateTikcetReplyMutation,
  useDeleteNotificationMutation,
  useUpdateNotificationMutation,
  useGetSpecficTicketQuery,
  useGetSpecficUserDetailsQuery,
  useGetClosedTicketsQuery,
  useCustomProfileQuery,
  useGetInvoiceQuery,
  useGetInvoicesGlobalQuery,
  useGetProductsQuery,
  usePurchaseProductMutation,
  useGetSpaceTypesQuery,
  useGetPurchasesQuery,
  useGetDiscountsQuery,
  useGetCompaniesUserQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useCheckHotDeskAvailibilityMutation,
  useViewHotDeskQuery,
} = spaceApi;
