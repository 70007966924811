import React, { useEffect, useState } from 'react';
import { Container, Card, Grid, Typography, Stack, Box, Avatar, Chip, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import Iconify from '../components/iconify';
import Loader from '../components/Loader/Loader';
import { useGetCommunitiesQuery, useGetPackagesQuery } from '../Slices/spaceApi';
import { ConfirmComponent } from '../utils/Alert';

function CommunitiesPage() {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);
  const { data, isLoading } = useGetCommunitiesQuery({ spaceId: spaceId || currentSpaceId });

  const [dailog, setDailog] = useState(false);
  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  useEffect(() => {
    if (!checkOldUser) {
      setDailog(true);
    } else {
      setDailog(false);
    }
  }, [checkOldUser]);

  const handleTypographyClick = (item) => {
    // Ensure item.email is defined before constructing the link
    if (item?.email) {
      // Construct the Gmail compose link
      const gmailComposeLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${encodeURIComponent(
        item.email
      )}`;

      // Open the link in a new tab
      window.open(gmailComposeLink, '_blank');
    }
  };
  if (isLoading || pacakgeLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Community Team | {name || currentName}</title>
      </Helmet>

      <ConfirmComponent openCheck={dailog} />

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Community Team
        </Typography>

        <Grid container spacing={2}>
          {data?.owner_spaces.length > 0 ? (
            data?.owner_spaces.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ padding: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box display={'flex'}>
                    <Box
                      sx={{
                        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        padding: '3px',
                        border: '1px dashed rgba(145, 158, 171, 0.2)',
                        borderRadius: '50%',
                        height: '48px',
                      }}
                    >
                      <Avatar alt={item.name} src={item?.profileImage} />
                    </Box>
                    <Stack ml={2} gap={1}>
                      <Typography fontWeight={'600'}>{item?.name}</Typography>
                      {item.roleName && (
                        <Box>
                          <Chip label={item.roleName} color="success" />
                        </Box>
                      )}
                      <Stack flexDirection={'row'} gap={1} mt={1}>
                        <Button
                          sx={{
                            minWidth: 'auto',
                            color: 'rgb(253, 169, 45)',
                            padding: '5px',
                            fontSize: '1.125rem',
                            borderRadius: '8px',
                            backgroundColor: 'rgba(253, 169, 45, 0.08)',
                            '&: hover': {
                              backgroundColor: 'rgba(253, 169, 45, 0.16)',
                            },
                          }}
                          onClick={() => handleTypographyClick(item)}
                        >
                          <Iconify icon="fluent:mail-24-filled" sx={{ width: '18px', height: '18px' }} />
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} textAlign={'center'}>
              <Typography variant="h6" paragraph>
                There's no Community Team
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default CommunitiesPage;
