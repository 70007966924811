import styled from '@emotion/styled';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { calculateDuration } from '../utils/getDuration';
import BookingCheck from '../components/Booking/BookingCheck';
import { timeStampCalculateDuration, timestampToDateTime } from '../utils/timeStamptoDateTime';
import Iconify from '../components/iconify/Iconify';
import { useGetBookingsQuery, useGetPastBookingsQuery } from '../Slices/spaceApi';
import Loader from '../components/Loader/Loader';

function BookingPage() {
  const { spaceId, currentSpaceId, currentOpenDays, openDays, name, currentName } = useSelector(
    (item) => item.spaceReducer
  );

  const location = useLocation();
  const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))({
    '& .MuiTabs-indicatorSpan': {},
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 'auto',
    '&.Mui-focusVisible': {},
  }));

  const [value, setValue] = useState(0);
  const [selectBooking, setSelectBooking] = useState();
  const [selectBookingIndex, setSelectedBookingIndex] = useState();

  const [type, setType] = useState({ title: 'Resources', value: 1 });
  // const [typePast, setTypePast] = useState({ title: 'Meeting Rooms', value: 1 });

  const handleType = (event, newValue) => {
    if (newValue) {
      // User has selected a value
      setType(newValue);
    } else {
      // User has cleared the selection, update type to 1
      setType({ title: 'Resources', value: 1 });
    }
  };

  useEffect(() => {
    // Parse the tabValue from the query parameters and set the initial value accordingly
    const searchParams = new URLSearchParams(location.search);
    const hotDesk = searchParams.get('hotdesk');

    if (hotDesk) {
      setType({ title: 'Hotdesks', value: 2 });
    }
  }, [location.search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const types = [
    { title: 'Resources', value: 1 },
    { title: 'Hotdesks', value: 2 },
  ];

  const defaultProps1 = {
    options: types,
    getOptionLabel: (option) => option.title,
  };

  const {
    data: bookingData,
    isLoading,
    isFetching: bookingFetching,
  } = useGetBookingsQuery(
    {
      spaceId: spaceId || currentSpaceId,
      type: type?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      query: {
        type,
      },
    }
  );

  const {
    data: pastBookingData,
    isLoading: pastLoading,
    isFetching: pastBookingFetching,
  } = useGetPastBookingsQuery(
    {
      spaceId: spaceId || currentSpaceId,
      type: type?.value,
    },
    {
      refetchOnMountOrArgChange: true,
      query: {
        type,
      },
    }
  );

  const [editStates, setEditStates] = useState();

  useEffect(() => {
    if (bookingData?.bookingss?.length > 0) {
      setEditStates(bookingData?.bookingss?.map(() => false));
    }
  }, [bookingData]);

  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = (item, idx) => {
    setSelectedBookingIndex(idx);
    setSelectBooking(item);
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const dayHoursArray = openDays?.length > 0 ? openDays : JSON.parse(currentOpenDays);

  // Mapping of numeric day values to day names
  const dayMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };

  const dayHoursWithNames = dayHoursArray.map((item) => ({
    day: dayMap[item.day],
    hours: item.hours,
  }));

  if (value === 0 ? isLoading : pastLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Booking | {name || currentName} </title>
      </Helmet>

      <Container>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Bookings
          </Typography>

          <RouterLink to="/bookingEnquiry" style={{ textDecoration: 'none' }}>
            <Button sx={{ float: 'right' }} variant="contained">
              Create Booking
            </Button>
          </RouterLink>
        </Stack>

        <StyledTabs
          value={value}
          onChange={handleChange}
          sx={{
            mb: 5,
            '& .MuiTabs-indicator': {
              backgroundColor: (theme) => theme.palette.text.primary,
            },
            '&.Mui-focusVisible': {
              backgroundColor: '',
              outline: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: 'transparent',

                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="material-symbols:event-upcoming-rounded" sx={{ marginRight: '10px' }} />
                Upcoming
              </Typography>
            }
          />
          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary,
                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="wpf:past" sx={{ marginRight: '10px' }} />
                Past
              </Typography>
            }
          />
        </StyledTabs>

        {value === 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  value={type}
                  onChange={handleType}
                  fullWidth
                  {...defaultProps1}
                  name="selectType"
                  id="selectType"
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      sx={{ fontWeight: 'fontWeightBold' }}
                      {...params}
                      label="Select room type"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {bookingData?.bookingss?.length > 0 ? (
                bookingData?.bookingss?.map((item, idx) => (
                  <Grid item xs={12} md={3} key={idx}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                        filter: bookingFetching ? 'blur(5px)' : 'none',
                      }}
                      onClick={bookingFetching ? null : () => handleOpenFilter(item, idx)}
                    >
                      <Stack>
                        <Stack padding={'20px 16px 8px'} gap={'16px'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography display={'flex'} variant="variant1" flexDirection={'column'}>
                              <Stack flexDirection={'row'} justifyContent={'space-between'} mb={2}>
                                {type?.value === 2 ? 'Hot desk' : item?.meetingRoom?.title}

                                <Chip
                                  label={
                                    item?.approved === 3
                                      ? 'Pending'
                                      : item?.approved === 2
                                      ? 'Not Approved'
                                      : 'Approved'
                                  }
                                  color={item?.approved === 3 ? 'info' : item?.approved === 2 ? 'error' : 'success'}
                                />
                              </Stack>
                              <Typography variant="subtitle2">{timestampToDateTime(item?.startTime)}</Typography>
                            </Typography>
                          </Box>

                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Iconify icon="uil:calender" sx={{ width: '15px', mr: 1 }} />
                              <Typography variant="subtitle2">
                                {type?.value === 2
                                  ? dayHoursWithNames?.find(
                                      (hoursItem) => hoursItem.day === dayjs.unix(item?.startTime).format('dddd')
                                    )?.hours || 'N/A'
                                  : timeStampCalculateDuration(item.startTime, item.endTime)}
                              </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                              <Iconify icon="tdesign:member" sx={{ width: '15px', mr: 1 }} />
                              <Typography variant="subtitle2">{item?.capacity} Persons</Typography>
                            </Box>
                          </Box>
                        </Stack>

                        <Box padding={'8px'} position={'relative'} borderRadius={'12px'}>
                          {type?.value === 1 && (
                            <Box style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%' }}>
                              <img
                                src={item?.meetingRoom?.imageUrls}
                                alt=""
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover', // This property maintains the aspect ratio
                                  borderRadius: '12px',
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant="h6" paragraph>
                    {type?.value === 1 ? 'There is no booking for resource ' : 'There is no booking for Hot Desk'}
                  </Typography>
                </Grid>
              )}

              {selectBooking && (
                <BookingCheck
                  openFilter={openFilter}
                  onCloseFilter={handleCloseFilter}
                  name={selectBooking?.meetingRoom?.title || 'Hot Desk'}
                  meetingRoomImage={selectBooking?.meetingRoomImage}
                  price={selectBooking?.meetingRoom?.price}
                  meetingRoomCapacity={selectBooking?.capacity}
                  type={type?.value}
                  startDateTime={selectBooking?.startTime}
                  endDateTime={selectBooking?.endTime}
                  facilities={selectBooking?.facilities}
                  description={selectBooking?.description}
                  id={selectBooking?.id}
                  meetingRoomId={selectBooking?.meetingRoom?.id}
                  inclusiveTax={selectBooking?.meetingRoom?.inclusiveTax}
                  location={selectBooking?.location}
                  editStates={editStates}
                  idx={selectBookingIndex}
                  setEditStates={setEditStates}
                  spaceType={selectBooking?.meetingRoom?.spaceType}
                />
              )}
            </Grid>
          </>
        )}

        {value === 1 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  value={type}
                  onChange={handleType}
                  fullWidth
                  {...defaultProps1}
                  name="selectType1"
                  id="selectType1"
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      sx={{ fontWeight: 'fontWeightBold' }}
                      {...params}
                      label="Select room type"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {pastBookingData?.bookingss?.length > 0 ? (
                pastBookingData?.bookingss?.map((item, idx) => (
                  <Grid item xs={12} md={3} key={idx}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        filter: pastBookingFetching ? 'blur(5px)' : 'none',
                      }}
                    >
                      <Stack>
                        <Stack padding={'20px 16px 8px'} gap={'16px'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Typography display={'flex'} variant="variant1" flexDirection={'column'}>
                              {item?.meetingRoom?.title}
                              <Typography variant="subtitle2">{timestampToDateTime(item?.startTime)}</Typography>
                            </Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Iconify icon="uil:calender" sx={{ width: '15px', mr: 1 }} />
                              <Typography variant="subtitle2">
                                {timeStampCalculateDuration(item?.startTime, item?.endTime)}
                              </Typography>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                              <Iconify icon="tdesign:member" sx={{ width: '15px', mr: 1 }} />
                              <Typography variant="subtitle2">{item?.capacity} Persons</Typography>
                            </Box>
                          </Box>
                        </Stack>

                        <Box padding={'8px'} position={'relative'} borderRadius={'12px'}>
                          {type?.value === 1 && (
                            <Box style={{ position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%' }}>
                              <img
                                src={item?.meetingRoom?.imageUrls}
                                alt=""
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover', // This property maintains the aspect ratio
                                  borderRadius: '12px',
                                }}
                              />
                            </Box>
                          )}

                          {item?.meetingRoom?.price && type?.value === 1 && (
                            <Typography
                              position={'absolute'}
                              variant="caption"
                              bottom={12}
                              right={15}
                              alignItems={'center'}
                              display={'flex'}
                              color={'white'}
                              sx={{
                                backgroundColor: 'rgb(33, 43, 54)',
                                padding: '0px 6px',
                                height: '24px',
                                borderRadius: '4px',
                              }}
                            >
                              {item?.meetingRoom?.price} {item?.meetingRoom?.space?.currency}
                            </Typography>
                          )}
                        </Box>
                      </Stack>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant="h6" paragraph>
                    {type?.value === 1
                      ? 'There is no past booking for resource '
                      : 'There is no  past booking for Hot Desk'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}

export default BookingPage;
