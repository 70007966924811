import { Checkbox, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useCheckHotDeskAvailibilityMutation } from '../../Slices/spaceApi';
import { getNextOpenDate } from '../../utils/nextOpenDay';

const EnquiryProductForm = ({
  control,
  product,
  trigger,
  errors,
  publicRoom,
  register,
  setValue,
  weekdaysData,
  hotDesk,
  hotDeskDate,
  duration,
  categories,
  products,
  watch,
}) => {
  const { openDays, currentOpenDays } = useSelector((item) => item.spaceReducer);

  const currentDate = dayjs();

  const minDate = currentDate; // You can set a minimum date here

  const validity = duration?.spaceDurations?.find((duration, idx) => duration.id === product?.duration)?.day;

  const maxDate = null; // You can set a maximum date here

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date);
    setValue(`startDate`, formattedDate);
  };

  const weekdays = publicRoom ? weekdaysData : openDays?.length > 0 ? openDays : JSON.parse(currentOpenDays);

  useEffect(() => {
    if (products?.products?.length === 0)
      if (hotDesk && hotDeskDate) {
        const formattedDate = dayjs(hotDeskDate);
        setValue(`startDate`, formattedDate);
      } else {
        setValue(
          `startDate`,
          getNextOpenDate(publicRoom ? weekdays : openDays, publicRoom ? weekdays : currentOpenDays)
        );
      }
  }, []);

  const isWeekdayDisabled = (date) => {
    const dayOfWeek = date.day();

    // Check if the day is not marked as "open" in the weekdays array
    const isDayOff = !weekdays?.some((day) => day.day === (dayOfWeek === 0 ? 7 : dayOfWeek));

    return isDayOff;
  };

  return (
    <>
      {publicRoom && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" mb={1}>
              Customer Information
            </Typography>

            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              {...register('name', {
                required: 'Name is required',
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
              onBlur={() => trigger('name')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email address',
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
              onBlur={() => trigger('email')}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="phone_number"
              control={control}
              rules={{
                required: 'Phone Number is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter only numeric digits.',
                },
                minLength: {
                  value: 11,
                  message: 'Phone number must have at least 11 characters',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Phone Number"
                  {...field}
                  error={Boolean(errors.phone_number)}
                  helperText={errors.phone_number ? errors.phone_number.message : ''}
                  onBlur={() => trigger('phone_number')} // Trigger validation onBlur
                />
              )}
            />
          </Grid>
        </>
      )}
      {publicRoom && <Divider />}
      {product?.manageStock && (
        <Controller
          name={`quantity_${product.id}`}
          control={control}
          rules={{
            required: 'Quantity is required',
            pattern: {
              value: /^[0-9]+$/,
              message: 'Please enter only numeric digits.',
            },
            validate: (value) => {
              if (product.manageStock) {
                const maxQuantity = product.quantity;
                const enteredQuantity = parseInt(value, 10);
                // eslint-disable-next-line no-restricted-globals
                if (isNaN(enteredQuantity) || enteredQuantity > maxQuantity) {
                  return `Quantity must be less than or equal to product capacity which is ${product.quantity}`;
                }
              }
              return true;
            },
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Quantity"
              {...field}
              error={Boolean(errors[`quantity_${product.id}`])}
              helperText={errors[`quantity_${product.id}`] ? errors[`quantity_${product.id}`].message : ''}
              onBlur={() => trigger(`quantity_${product.id}`)} // Trigger validation onBlur
            />
          )}
        />
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <Controller
            name={`startDate`}
            control={control}
            rules={{ required: 'Start Date is required' }}
            render={({ field }) => (
              <DatePicker
                sx={{ width: '100%' }}
                {...field}
                label="Select Date"
                disabled={products?.products?.length >= 1}
                // minDate={publicRoom && hotDeskDate ? dayjs(hotDeskDate).subtract(validity) : minDate}
                minDate={publicRoom && hotDeskDate ? dayjs(hotDeskDate).subtract(validity, 'days') : minDate}
                disablePast
                maxDate={publicRoom && hotDeskDate ? dayjs(hotDeskDate) : maxDate}
                shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                format="YYYY-MM-DD"
                onChange={(date) => {
                  handleDateChange(date);
                }}
                error={!!errors.startDate}
                helperText={errors.startDate && errors.startDate.message}
              />
            )}
          />
        </DemoContainer>
      </LocalizationProvider>

      {!publicRoom &&
        product?.benefits?.dayPass?.quantity > 0 &&
        categories?.some((item) => item.id === product?.category && item.name === 'Hot Desks') && (
          <FormControlLabel
            control={
              <Controller
                name={'hotDesk'}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    disabled={products?.products?.length >= 1}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={'Want to book hot desk on this date?'}
          />
        )}
    </>
  );
};
export default EnquiryProductForm;
